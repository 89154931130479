import React from 'react';
import {
    Box,
    CircularProgress,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    useMediaQuery,
    Tooltip
} from '@material-ui/core';
import ModalChargebackAction from 'components/modal-chargeback-list-action/modal-chargeback-list-action.container';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AddIcon from '@material-ui/icons/Add';
import FiberManualRecordTwoToneIcon from '@material-ui/icons/FiberManualRecordTwoTone';
import TransactionStatusLabel from '../transaction-tables/transaction-status-label/transaction-status-label.container';
import TransactionRefund from '../../transaction/transaction-refund/transaction-refund.container';
import {
    filterRefundDescription,
    formatCurrency,
    formatDateTimeByFormatString,
    filterDescription,
    formatAmount,
    merchantTypesEnum
} from '../../../utils/helper';
import styles from './payment-details.module.scss';
import { getCurrencySymbol, getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';
import { SnackBarAndAlert, LoadingScreen } from 'components';
import { UserType } from 'utils/enums/UserType';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { ResellerType } from 'utils/enums/ResellerType';
import { cleverTapClick } from '../../../utils/helper';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { withStyles } from '@material-ui/core/styles';
import { AcquirersNameMap } from 'utils/enums/Acquirers';
import GitfCardLableChipContainer from '../transaction-tables/gift-card-chip/gift-card-lable-chip.conatiner';
import { PaymentProvider } from 'utils/enums/PaymentProvider';

const Tooltips = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'white',
        color: 'black',
        padding: '5px',
        maxWidth: '100%',

        border: '1px solid black'
    }
}))(Tooltip);
const PaymentsDetailsComponent = (props) => {
    const { globalState } = useGlobalStateContext();
    const {
        loading,
        error,
        payment,
        show,
        setShow,
        handleCopyPaymentId,
        isCopyAlertOpen,
        closeCopyAlert,
        status,
        setOpenModal,
        openModal,
        handleModal,
        setLoading,
        chargebackStatus,
        setChargebackListStatus,
        chargebackListStatus,
        snackbarMessage,
        setSnackbarMessage,
        snackbarMessageType,
        setSnackbarMessageType,
        showSnackbarStatus,
        setShowSnackbarStatus,
        setChargebackStatus,
        defaultchargeback,
        showChargeback,
        setShowChargeback,
        chargebackExists,
        isAddCreditTrannsaction,
        fees,
        chipTexts,
        giftCardExits
    } = props;
    let hidePaymentActions = globalState.merchant?.userRole === 'User';
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isDatmanReseller = globalState.reseller?.name === ResellerType.DATMAN;
    const showAcquirer = isAdmin && isDatmanReseller;
    const noChargeBack = ['ADYEN', 'CARDSTREAM-CH', 'CHECKOUT-HF', 'VOUCHER-CREDIT'];
    const hideSensitiveDetails = globalState?.merchant?.merchantType === merchantTypesEnum.BIG_TABLE;

    const TooltipComp = () => {
        return (
            <Tooltips
                title={
                    fees.length > 0 ? (
                        fees.map((data) => (
                            <div>
                                <span
                                    style={{
                                        marginBottom: '0.3rem'
                                    }}
                                >
                                    {data.fee_description} :{' '}
                                    {formatCurrency(
                                        data.fee_amount,
                                        getCurrencyThreeAlpha(globalState.merchant?.country)
                                    )}
                                </span>
                            </div>
                        ))
                    ) : (
                        <div>
                            <span style={{ marginBottom: '0.3rem' }}>
                                Datman Fees:
                                {formatCurrency(payment.fees, getCurrencyThreeAlpha(globalState.merchant?.country))}
                            </span>
                        </div>
                    )
                }
                arrow
                placement="top"
                className={styles.toolTip}
            >
                <InfoOutlinedIcon
                    style={{
                        fontSize: '20px',
                        color: '#005896',
                        marginLeft: '5px'
                    }}
                />
            </Tooltips>
        );
    };
    return (
        <React.Fragment>
            {loading ? (
                <Box textAlign="center" py={5}>
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    {error ? (
                        <Box textAlign="center" py={5}>
                            An error occurred, please try reloading your browser.
                        </Box>
                    ) : (
                        <React.Fragment>
                            <div className={styles.containerDiv}>
                                <div className={styles.headerDetails}>
                                    <div className={styles.headerDetails_title}>
                                        <div className={styles.contentBlock}>
                                            <div>
                                                <Typography color={'primary'}>
                                                    <span className={styles.pageTitle}>Payment</span>
                                                    <span className={styles.spanRefId}>
                                                        {payment.id}
                                                        <sup>
                                                            <FileCopyOutlinedIcon
                                                                fontSize="inherit"
                                                                onClick={handleCopyPaymentId}
                                                                className={styles.copyIcon}
                                                            />
                                                        </sup>
                                                    </span>
                                                    <span>
                                                        {giftCardExits && (
                                                            <GitfCardLableChipContainer status={chipTexts} />
                                                        )}
                                                    </span>
                                                </Typography>
                                                {isCopyAlertOpen && (
                                                    <SnackBarAndAlert
                                                        open={isCopyAlertOpen}
                                                        onClose={closeCopyAlert}
                                                        type={'success'}
                                                    >
                                                        Successfully copied the payment reference ID.
                                                    </SnackBarAndAlert>
                                                )}
                                            </div>
                                            <div className={styles.amount_items}>
                                                <span className={styles.amount_items__currency}>
                                                    {getCurrencySymbol(globalState.merchant?.country)}
                                                </span>
                                                <span>{formatAmount(payment.total)}</span>
                                                <span className={styles.spnaCurrency}>
                                                    {getCurrencyThreeAlpha(globalState.merchant?.country)}
                                                </span>
                                            </div>
                                        </div>
                                        {Number(payment.total) > 0 &&
                                            !payment.refund &&
                                            !isAddCreditTrannsaction &&
                                            isAdmin &&
                                            !noChargeBack.includes(payment?.payment_provider) &&
                                            isDatmanReseller &&
                                            !giftCardExits && (
                                                <div>
                                                    <div>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handleModal()}
                                                            disabled={chargebackExists}
                                                        >
                                                            + Add Chargebacks
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                    <div className={styles.content}>
                                        {
                                            //remove false when modify api to get payment method
                                            false && (
                                                <Box px={2}>
                                                    <div>
                                                        <span>Payment method</span>
                                                    </div>
                                                    <div>
                                                        <span>{payment.method}</span>
                                                    </div>
                                                </Box>
                                            )
                                        }
                                        {
                                            //remove false when modify api to get risk parameter
                                            false && (
                                                <Box px={2}>
                                                    <div>
                                                        <span>Risk</span>
                                                    </div>
                                                    <div>
                                                        <span>{payment.risk}</span>
                                                    </div>
                                                </Box>
                                            )
                                        }
                                        {hidePaymentActions ||
                                        payment.total <= 0 ||
                                        payment.payed <= 0 ||
                                        !payment.order_id ||
                                        (payment.payment_status ? payment.payment_status !== 'OK' : false) ||
                                        payment.payment_provider === AcquirersNameMap.VOUCHER_CREDIT ||
                                        payment.refund ||
                                        giftCardExits ? null : (
                                            <Box px={2}>
                                                <div></div>
                                                <div>
                                                    {
                                                        <Button
                                                            color="primary"
                                                            className={styles.button}
                                                            variant="contained"
                                                            onClick={() => {
                                                                setShow(true);
                                                                const data = {
                                                                    refund_amount: props.payment.total,
                                                                    time_stamp: new Date()
                                                                };
                                                                cleverTapClick('Refund_txn_click', globalState, data);
                                                            }}
                                                            disabled={
                                                                globalState.merchant.status === MerchantStatus.WATCHLIST
                                                            }
                                                        >
                                                            Refund
                                                        </Button>
                                                    }
                                                </div>
                                                <TransactionRefund {...props} show={show} />
                                            </Box>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {
                                //remove false when modify api to get timeline details method
                                false && (
                                    <div className={styles.timelineDiv}>
                                        <Box width="100%">
                                            <div className={styles.headerDetails}>
                                                <Typography color={'primary'}>
                                                    <span className={styles.pageTitle}>Timeline</span>
                                                </Typography>
                                                <Box pr={2}>
                                                    <Button color="primary" size="small" startIcon={<AddIcon />}>
                                                        Add note
                                                    </Button>
                                                </Box>
                                            </div>
                                            <div>
                                                {payment.timeline &&
                                                    payment.timeline.map((timeline) => {
                                                        return (
                                                            <div className={styles.timeline}>
                                                                <div>
                                                                    <FiberManualRecordTwoToneIcon
                                                                        fontSize="inherit"
                                                                        htmlColor="#04dc04"
                                                                    />
                                                                    <span> {timeline.description}</span>
                                                                </div>
                                                                <Box pl={2}>
                                                                    {formatDateTimeByFormatString(
                                                                        timeline.created_at,
                                                                        'DD MMM HH:mm'
                                                                    )}
                                                                </Box>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </Box>
                                    </div>
                                )
                            }
                            {mediaLessThan600px ? (
                                <div className={styles.responsiveDiv}>
                                    <Box width="100%">
                                        <div>
                                            <Typography color={'primary'}>
                                                <span className={styles.pageTitle}>Payment details</span>
                                            </Typography>
                                        </div>
                                        <Box mt={2} className={styles.overflowX}>
                                            <Table>
                                                <TableRow>
                                                    <TableCell
                                                        style={{ border: 'none', paddingRight: '0px' }}
                                                        variant="head"
                                                    >
                                                        Amount
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {formatCurrency(
                                                            payment.total,
                                                            getCurrencyThreeAlpha(globalState.merchant?.country)
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{ border: 'none', paddingRight: '0px' }}
                                                        variant="head"
                                                    >
                                                        Fee
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {formatCurrency(
                                                            payment?.fees,
                                                            getCurrencyThreeAlpha(globalState.merchant?.country)
                                                        )}
                                                        {Number(payment?.fees) !== 0 && TooltipComp()}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{ border: 'none', paddingRight: '0px' }}
                                                        variant="head"
                                                    >
                                                        Net
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {formatCurrency(
                                                            payment.payed,
                                                            getCurrencyThreeAlpha(globalState.merchant?.country)
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ border: 'none' }} variant="head">
                                                        Date
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {formatDateTimeByFormatString(payment.time, 'DD MMM HH:mm')}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ border: 'none' }} variant="head">
                                                        Card
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {payment.last_four_digits ||
                                                        payment.card_last_four_digits ||
                                                        payment.last_4_digits
                                                            ? `********${
                                                                  payment.last_four_digits ||
                                                                  payment.card_last_four_digits ||
                                                                  payment.last_4_digits
                                                              }`
                                                            : `-`}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{ border: 'none', paddingRight: '0px' }}
                                                        variant="head"
                                                    >
                                                        Description
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {+payment.total < 0 ? (
                                                            <span>
                                                                {filterRefundDescription(payment.refund) || '-'}
                                                            </span>
                                                        ) : payment.more_info ? (
                                                            filterDescription(payment.more_info)
                                                        ) : payment.description ? (
                                                            payment.description
                                                        ) : (
                                                            <span>&mdash;</span>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{ border: 'none', paddingRight: '0px' }}
                                                        variant="head"
                                                    >
                                                        Status
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {payment.total <= 0 && payment.refund ? (
                                                            <TransactionStatusLabel status={'REFUNDED'} />
                                                        ) : (
                                                            <TransactionStatusLabel status={status} />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            </Table>
                                        </Box>
                                    </Box>
                                </div>
                            ) : (
                                <div className={styles.paymentDetailsDiv}>
                                    <Box width="100%">
                                        <div>
                                            <Typography color={'primary'}>
                                                <span className={styles.pageTitle}>Payment details</span>
                                            </Typography>
                                        </div>
                                        <Box mt={2} className={styles.overflowX}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow className={styles.tableHeader}>
                                                        {/* <TableCell>Statement description</TableCell> */}
                                                        <TableCell>Amount</TableCell>
                                                        <TableCell>Fee</TableCell>
                                                        <TableCell>Net</TableCell>
                                                        <TableCell>Date</TableCell>
                                                        <TableCell>Card</TableCell>
                                                        <TableCell>Description</TableCell>
                                                        <TableCell>Status</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            {formatCurrency(
                                                                payment.total,
                                                                getCurrencyThreeAlpha(globalState.merchant?.country)
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            <span
                                                                style={{
                                                                    border: 'none',
                                                                    paddingLeft: '0px',
                                                                    display: 'flex',
                                                                    width: 'max-content'
                                                                }}
                                                            >
                                                                {formatCurrency(
                                                                    payment.fees,
                                                                    getCurrencyThreeAlpha(globalState.merchant?.country)
                                                                )}
                                                                {(payment?.fees > 0 || payment?.fees < 0) &&
                                                                    TooltipComp()}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span
                                                                style={{
                                                                    border: 'none',
                                                                    paddingLeft: '0px',
                                                                    display: 'flex',
                                                                    width: 'max-content'
                                                                }}
                                                            >
                                                                {formatCurrency(
                                                                    payment.payed,
                                                                    getCurrencyThreeAlpha(globalState.merchant?.country)
                                                                )}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatDateTimeByFormatString(payment.time, 'DD MMM HH:mm')}
                                                        </TableCell>
                                                        <TableCell>
                                                            {payment.last_four_digits ||
                                                            payment.card_last_four_digits ||
                                                            payment.last_4_digits
                                                                ? `********${
                                                                      payment.last_four_digits ||
                                                                      payment.card_last_four_digits ||
                                                                      payment.last_4_digits
                                                                  }`
                                                                : `-`}
                                                        </TableCell>
                                                        <TableCell>
                                                            {+payment.total < 0 ? (
                                                                <span>
                                                                    {filterRefundDescription(payment.refund) || '-'}
                                                                </span>
                                                            ) : payment.more_info ? (
                                                                filterDescription(payment.more_info)
                                                            ) : payment.description ? (
                                                                payment.description
                                                            ) : (
                                                                <span>&mdash;</span>
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {(payment.total <= 0 && payment.refund) ||
                                                            payment.firstname.includes('Charge back') ? (
                                                                <TransactionStatusLabel status={'REFUNDED'} />
                                                            ) : (
                                                                <TransactionStatusLabel status={status} />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Box>
                                </div>
                            )}
                            {mediaLessThan600px ? (
                                <div className={styles.responsiveDiv}>
                                    <Box width="100%">
                                        <div>
                                            <Typography color={'primary'}>
                                                <span className={styles.pageTitle}>Customer Details</span>
                                            </Typography>
                                        </div>
                                        <Box mt={2} className={styles.overflowX}>
                                            <Table>
                                                <TableRow>
                                                    <TableCell
                                                        style={{ border: 'none', paddingRight: '0px' }}
                                                        variant="head"
                                                    >
                                                        Customer Name
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {payment.firstname}&nbsp;{payment.lastname}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{ border: 'none', paddingRight: '40px' }}
                                                        variant="head"
                                                    >
                                                        Customer Address
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {payment.address}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ display: 'none' }}>
                                                    <TableCell style={{ border: 'none' }} variant="head">
                                                        Customer Email
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none' }}>
                                                        {payment.email || '-'}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ display: 'none' }}>
                                                    <TableCell style={{ border: 'none' }} variant="head">
                                                        Customer Phone Number
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none' }}>
                                                        {payment.phone_number || '-'}
                                                    </TableCell>
                                                </TableRow>
                                            </Table>
                                        </Box>
                                    </Box>
                                </div>
                            ) : (
                                <div className={styles.paymentDetailsDiv}>
                                    <Box width="100%">
                                        <div>
                                            <Typography color={'primary'}>
                                                <span className={styles.pageTitle}>Customer Details</span>
                                            </Typography>
                                        </div>
                                        <Box mt={2} className={styles.overflowX}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow className={styles.tableHeader}>
                                                        {/* <TableCell>Statement description</TableCell> */}
                                                        <TableCell>Customer Name</TableCell>
                                                        <TableCell>Customer Address</TableCell>
                                                        <TableCell>Customer Email</TableCell>
                                                        <TableCell>Customer Phone Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        {/* <TableCell>{payment.statement_description || '-'}</TableCell> */}
                                                        <TableCell>
                                                            {hideSensitiveDetails
                                                                ? 'REDACTED'
                                                                : `${payment.firstname} ${payment.lastname}`}
                                                        </TableCell>
                                                        <TableCell>
                                                            {hideSensitiveDetails
                                                                ? 'REDACTED'
                                                                : payment.address?.trim()}
                                                        </TableCell>
                                                        <TableCell>{payment.email || '-'}</TableCell>
                                                        <TableCell>{payment.phone_number || '-'}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Box>
                                </div>
                            )}
                            {isAdmin &&
                                (mediaLessThan600px ? (
                                    <div className={styles.responsiveDiv}>
                                        <Box width="100%">
                                            <div>
                                                <Typography color={'primary'}>
                                                    <span className={styles.pageTitle}>Additional Information</span>
                                                </Typography>
                                            </div>
                                            <Box mt={2} className={styles.overflowX}>
                                                <Table>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ border: 'none', paddingRight: '0px' }}
                                                            variant="head"
                                                        >
                                                            Transaction Reference
                                                        </TableCell>
                                                        <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                            {payment.TxnReference ? payment.TxnReference : '-'}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ border: 'none', paddingRight: '0px' }}
                                                            variant="head"
                                                        >
                                                            3DS status
                                                        </TableCell>
                                                        <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                            {payment.ThreeDsStatus ? payment.ThreeDsStatus : '-'}
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </Box>
                                        </Box>
                                    </div>
                                ) : (
                                    <div className={styles.paymentDetailsDiv}>
                                        <Box width="100%">
                                            <div>
                                                <Typography color={'primary'}>
                                                    <span className={styles.pageTitle}>Additional Information</span>
                                                </Typography>
                                            </div>
                                            <Box mt={2} className={styles.overflowX}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow className={styles.tableHeader}>
                                                            {/* <TableCell>Statement description</TableCell> */}
                                                            <TableCell>Transaction Reference</TableCell>
                                                            {payment.payment_provider !== 'ADYEN' && showAcquirer && (
                                                                <TableCell>Acquirer ID</TableCell>
                                                            )}
                                                            <TableCell>3DS Status</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            {/* <TableCell>{payment.statement_description || '-'}</TableCell> */}
                                                            <TableCell>
                                                                {payment.TxnReference ? payment.TxnReference : '-'}
                                                            </TableCell>

                                                            {/* HardCoding Temporarily */}
                                                            {payment.payment_provider !== 'ADYEN' && showAcquirer && (
                                                                <TableCell>{payment.payment_provider}</TableCell>
                                                            )}
                                                            <TableCell>
                                                                {payment.ThreeDsStatus ? payment.ThreeDsStatus : '-'}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Box>
                                    </div>
                                ))}
                            {snackbarMessage && (
                                <SnackBarAndAlert
                                    open={snackbarMessage}
                                    type={snackbarMessageType}
                                    onClose={() => setSnackbarMessage('')}
                                >
                                    {snackbarMessage}
                                </SnackBarAndAlert>
                            )}
                            <LoadingScreen open={loading} />
                            <ModalChargebackAction
                                setOpenModal={setOpenModal}
                                openModal={openModal}
                                setLoading={setLoading}
                                loading={loading}
                                chargebackStatus={chargebackStatus}
                                chargebackTransactionId={payment.id}
                                setChargebackListStatus={setChargebackListStatus}
                                chargebackListStatus={chargebackListStatus}
                                snackbarMessage={snackbarMessage}
                                setSnackbarMessage={setSnackbarMessage}
                                snackbarMessageType={setSnackbarMessageType}
                                setSnackbarMessageType={setSnackbarMessageType}
                                showSnackbarStatus={showSnackbarStatus}
                                setShowSnackbarStatus={setShowSnackbarStatus}
                                setChargebackStatus={setChargebackStatus}
                                defaultchargeback={defaultchargeback}
                                showChargeback={showChargeback}
                                setShowChargeback={setShowChargeback}
                                chargebackExists={chargebackExists}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PaymentsDetailsComponent;
