import React, { useEffect, useRef, useState } from 'react';
import { useGlobalStateContext } from 'contexts';
import ModalAcceptTOS from './adyen-accept-TOS.component';
import AdyenDocumentViewer from '@adyen/adyen-document-viewer';
import '@adyen/adyen-document-viewer/dist/adyen-document-viewer.min.css';
import { AdyenService } from 'services';

function AdyenAcceptTOSModal(props) {
    const {
        openTOSModal,
        setOpenTOSModal,
        documentBase64,
        serviceDocumentId,
        loadTOSModal,
        setTermsOfServiceTypes
    } = props;
    const { globalState } = useGlobalStateContext();
    const viewerRef = useRef(null);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (openTOSModal && documentBase64) {
            // Ensure the viewerRef is populated before initializing the document viewer
            const initializeDocumentViewer = () => {
                if (viewerRef.current) {
                    const base64STR = atob(documentBase64);
                    const parsedData = JSON.parse(base64STR);

                    const newDocumentViewer = new AdyenDocumentViewer(viewerRef.current, {
                        onExpandSection: (sectionTitle) => console.log(`${sectionTitle} expanded`),
                        multiple: false
                    });

                    newDocumentViewer.render(parsedData);
                }
            };

            // Use requestAnimationFrame to wait for the next render cycle
            requestAnimationFrame(initializeDocumentViewer);
        }
    }, [documentBase64, openTOSModal]);

    const acceptTermsAndConditon = async () => {
        setOpenTOSModal(false);
        const merchantId = globalState.merchant.id;
        const data = {
            termsOfServiceDocumentId: serviceDocumentId
        };
        const response = await AdyenService.adyenAcceptedTermsAndConditon(merchantId, data);
        if (response.isSuccesfully) {
            const data = await AdyenService.adyenGetTOSTypes(merchantId);
            if (data.isSuccesfully) {
                setTermsOfServiceTypes([]);
            }
        }
    };

    const handleCancleClick = async () => {
        setOpenTOSModal(false);
    };

    return (
        <ModalAcceptTOS
            openTOSModal={openTOSModal}
            handleCancleClick={handleCancleClick}
            viewerRef={viewerRef}
            checked={checked}
            setChecked={setChecked}
            loading={loadTOSModal}
            acceptTermsAndConditon={acceptTermsAndConditon}
        />
    );
}

export default AdyenAcceptTOSModal;
