import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Tooltip,
    TableFooter,
    TablePagination
} from '@material-ui/core';

import style from './document-review.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import TableHeader from '../../../payments/transaction-tables/table-header/table-header.container';
import { formatDateTimeByFormatString, stableSort, getComparator } from '../../../../utils/helper';
import { DocumentReviewStatus } from '../../../../utils/enums/DocumentReviewStatus';
import { DocumentReviewReasons } from '../../../../utils/enums/DocumentReviewReasons';
import { DocumentTypes, DocumentTypesId } from '../../../../utils/enums/DocumentType';
import { DocumentStatusColor } from 'utils/enums/DocumentStatusColors';
import { SnackBarAndAlert } from 'components';
import DialogViewDocuments from '../../admin-functions/view-documents/dialog-view-documents/dialog-view-documents.container';
import MerchantVerificationCheckList from '../../admin-functions/view-documents/merchant-verification-checklist/merchant-verification-checklist.container';
import { useGlobalStateContext } from 'contexts';
import { LoadingScreen } from 'components';
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: 'none'
    },
    container: {
        'overflow-y': 'visible'
    },
    table: {
        minWidth: '100%'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    selected: {},
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    checkbox: {
        color: '#8783b5'
    },
    'MuiIconButton-root': {
        padding: '0px 12px'
    }
}));

function DocumentReviewComponent(props) {
    const classes = useStyles();
    const { globalState } = useGlobalStateContext();

    const {
        documentToReview,
        documentReviewReason,
        setDocumentReviewReason,
        order,
        orderBy,
        documentType,
        setDocumentType,
        documentStatus,
        setDocumentStatus,
        filterDocuments,
        defaultDocumentType,
        isModalOpen,
        setIsModalOpen,
        isMerchantVerificationCheckListOpen,
        setIsMerchantVerificationCheckListOpen,
        merchantVerificationAcceptRejectMessage,
        setMerchantVerificationAcceptRejectMessage,
        showMessage,
        setShowMessage,
        messageType,
        setMessageType,
        orangeMessageStyle,
        setOrangeMessageStyle,
        documentIndex,
        setDocumentIndex,
        setDocumentsUpdated,
        setDocumentToReview,
        setShowAdyenMessage,
        showAdyenMessage,
        message,
        setMessage,
        updateDocumentTypeHandler,
        isLoading,
        count,
        rowsPerPage,
        page,
        handleChangePage,
        handleChangeRowsPerPage,
        uploadDocumentToAdyenAccount
    } = props;
    return (
        <Grid className={style.documentReviewContainer}>
            <Grid className={style.title}>
                <FormControl variant="outlined" className={style.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label" className={style.inputlabel}>
                        Document Review reason
                    </InputLabel>

                    <Select
                        className={style.documentSelectReason}
                        inputProps={{
                            className: style.selectDocumentReviewReason
                        }}
                        label="Document Review reason"
                        disableUnderline
                        value={documentReviewReason}
                        onChange={(e) => {
                            setDocumentReviewReason(e.target.value);
                            filterDocuments(e.target.value, documentType, documentStatus);
                        }}
                        id="document-review-status"
                    >
                        {Object.keys(DocumentReviewReasons).map((reason) => (
                            <MenuItem value={reason}>{DocumentReviewReasons[reason]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className={style.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label" className={style.inputlabel}>
                        Document type
                    </InputLabel>
                    <Select
                        className={style.documentSelectReason}
                        inputProps={{
                            className: style.selectDocumentReviewReason
                        }}
                        label="Document type"
                        disableUnderline
                        value={documentType}
                        onChange={(e) => {
                            setDocumentType(e.target.value);
                            filterDocuments(documentReviewReason, e.target.value, documentStatus);
                        }}
                        id="document-type"
                    >
                        {_.sortBy(DocumentTypes, [
                            function (docType) {
                                return docType.name;
                            }
                        ]).map((document) =>
                            document.id !== -1 ? (
                                <MenuItem value={document.name}>{document.name}</MenuItem>
                            ) : (
                                <MenuItem value={defaultDocumentType}>{defaultDocumentType}</MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className={style.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label" className={style.inputlabel}>
                        Document status
                    </InputLabel>
                    <Select
                        className={style.documentSelectReason}
                        inputProps={{
                            className: style.selectDocumentReviewReason
                        }}
                        label="Document status"
                        disableUnderline
                        value={documentStatus}
                        onChange={(e) => {
                            setDocumentStatus(e.target.value);
                            filterDocuments(documentReviewReason, documentType, e.target.value);
                        }}
                        id="document-type"
                    >
                        {Object.keys(DocumentReviewStatus).map((status) => (
                            <MenuItem value={status}>{DocumentReviewStatus[status]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid className={style.documentTable}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        stickyHeader
                        aria-label="enhanced table"
                    >
                        <TableHeader classes={classes} {...props} />
                        {!documentToReview || documentToReview.length === 0 ? (
                            <TableBody>
                                <TableRow className={style.rowData}>
                                    <TableCell colSpan={8} align="center">
                                        No records found
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {stableSort(documentToReview, getComparator(order, orderBy))?.map((document, index) => (
                                    <TableRow key={document.documentId}>
                                        <TableCell style={{ width: '180px' }}>
                                            <Select
                                                className={style.selectDocType}
                                                inputProps={{
                                                    className: style.selectDocumentReviewReason
                                                }}
                                                label="Document type"
                                                disableUnderline
                                                value={document.documentTypeName}
                                                onChange={(e) => {
                                                    updateDocumentTypeHandler(document.documentId, e.target.value);
                                                }}
                                                id="document-type"
                                            >
                                                {_.sortBy(DocumentTypes, [
                                                    function (o) {
                                                        return o.name;
                                                    }
                                                ]).map(
                                                    (document) =>
                                                        document.id !== -1 && (
                                                            <MenuItem value={document.name}>{document.name}</MenuItem>
                                                        )
                                                )}
                                            </Select>
                                        </TableCell>

                                        <TableCell className={style.documentFileName}>
                                            <Grid item container xs={12} md={12} justify="space-between">
                                                <Grid item xs={7} md={7}>
                                                    <Tooltip
                                                        title={document.filename}
                                                        className={style.documentNameHover}
                                                        placement="bottom-start"
                                                        style={{
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <div
                                                            className={style.fileName}
                                                            onClick={() => {
                                                                setIsModalOpen(true);
                                                                setDocumentIndex(index);
                                                            }}
                                                        >
                                                            {document.filename}
                                                        </div>
                                                    </Tooltip>
                                                </Grid>

                                                <Grid item xs={4} md={4}>
                                                    <span
                                                        className={style.documentReviewStatus}
                                                        style={DocumentStatusColor[document.documentStatus]}
                                                    >
                                                        {DocumentReviewStatus[document.documentStatus]}
                                                    </span>
                                                </Grid>
                                            </Grid>

                                            {globalState.merchant?.isAdyenMerchant &&
                                                [
                                                    DocumentTypesId.DRIVING_LICENCE,
                                                    DocumentTypesId.PASSPORT,
                                                    DocumentTypesId.PROOF_OF_ORGANIZATION_TAX_INFO,
                                                    DocumentTypesId.VAT_DOCUMENT,
                                                    DocumentTypesId.ID_PROOF_FRONT,
                                                    DocumentTypesId.ID_PROOF_BACK
                                                ].includes(document.docTypeId) &&
                                                ['NEED_APPROVAL', 'ACTIVE'].includes(document.documentStatus) && (
                                                    <Grid style={{ marginTop: '10px' }}>
                                                        <Button
                                                            className={style.adyenUpload}
                                                            onClick={() => {
                                                                uploadDocumentToAdyenAccount(
                                                                    document.documentId,
                                                                    index
                                                                );
                                                            }}
                                                        >
                                                            UPLOAD TO ADYEN
                                                        </Button>
                                                    </Grid>
                                                )}
                                        </TableCell>
                                        <TableCell className={style.documentUploadDate}>
                                            {formatDateTimeByFormatString(document.createdAt, 'DD MMM YYYY HH:mm')}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Grid>

            <Grid>
                {count !== 0 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                className={style.documentPagination}
                                rowsPerPageOptions={[10, 30, 50]}
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Grid>
            <MerchantVerificationCheckList
                setMerchantVerificationAcceptRejectMessage={setMerchantVerificationAcceptRejectMessage}
                setMessageType={setMessageType}
                setShowMessage={setShowMessage}
                setOrangeMessageStyle={setOrangeMessageStyle}
                isMerchantVerificationCheckListOpen={isMerchantVerificationCheckListOpen}
                setIsMerchantVerificationCheckListOpen={setIsMerchantVerificationCheckListOpen}
            />
            <DialogViewDocuments
                documentToReview={documentToReview}
                documentIndex={documentIndex}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                setIsMerchantVerificationCheckListOpen={setIsMerchantVerificationCheckListOpen}
                setDocumentsUpdated={setDocumentsUpdated}
                setDocumentToReview={setDocumentToReview}
            />
            {showMessage && (
                <SnackBarAndAlert
                    open={showMessage}
                    onClose={() => {
                        setOrangeMessageStyle(false);
                        setShowMessage(false);
                    }}
                    type={messageType}
                    orangeColor={orangeMessageStyle}
                >
                    {merchantVerificationAcceptRejectMessage}
                </SnackBarAndAlert>
            )}
            {showAdyenMessage && (
                <SnackBarAndAlert
                    open={setShowAdyenMessage}
                    onClose={() => {
                        setMessage('');
                        setShowAdyenMessage(false);
                    }}
                    type={messageType}
                >
                    {message}
                </SnackBarAndAlert>
            )}
            <LoadingScreen open={isLoading} />
        </Grid>
    );
}

DocumentReviewComponent.propTypes = {
    documentToReview: PropTypes.array,
    documentReviewReason: PropTypes.string,
    setDocumentReviewReason: PropTypes.func,
    filterDocumentsByReason: PropTypes.func,
    changeDocumentStatus: PropTypes.func,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    documentType: PropTypes.func,
    setDocumentType: PropTypes.string,
    filterDocumentsByDocumentType: PropTypes.func
};
export default DocumentReviewComponent;
