import React from 'react';
import styles from './manage-partners.module.scss';
import LoadingScreen from 'components/loading-screen/loading-screen.container';
import { SnackBarAndAlert } from 'components';
import { DNAEventStatus } from 'utils/enums/pdqOrderStatus';
import PropTypes from 'prop-types';
import ModalPDQConfirmation from 'components/modal-pdq-confirmation/modal-pdq-confirmation.container';
import { AdyenAccountStatusStringToEnum } from 'utils/enums/AdyenVariables';

import {
    Grid,
    Button,
    Tooltip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    Typography,
    ListItem
} from '@material-ui/core';
import { DnaFormModal } from 'components';
export default function ManagePartnersComponent(props) {
    const {
        error,
        toggleAcquirers,
        acquirers,
        submitPartnerApplication,
        isLoading,
        adyenAccountId,
        showMessage,
        setShowMessage,
        setMessage,
        messageType,
        message,
        row,
        dnaErrorList,
        dnaAccount,
        dnaApplicationId,
        needAdditionalInfoStatus,
        merchantData,
        setMerchantData,
        pdqFee,
        setPdqFee,
        openModal,
        setOpenModal,
        adminFunctions,
        onConfirm,
        modalLoading,
        isDnaDisabled,
        openDnaForm,
        closeAdyenAccount,
        setAdyenCloseModal,
        adyenCloseModal,
        isSuperAdmin,
        isAdyenOnboardingDisabled,
        disable
    } = props;

    return (
        <>
            <Grid container className={styles.acquirerTopGrid}>
                <Grid item xs={6}>
                    Acquirer
                </Grid>
                <Grid item xs={6}>
                    <div>
                        <input
                            className={styles.acquirerInput}
                            type="checkbox"
                            onChange={(e) => toggleAcquirers('adyen', e.currentTarget.checked)}
                            checked={acquirers.adyen}
                            disabled={!isAdyenOnboardingDisabled ? true : acquirers.adyen && adyenAccountId}
                        />
                        <span>Adyen</span>
                    </div>
                    <div>
                        <input
                            className={styles.acquirerInput}
                            type="checkbox"
                            onChange={(e) => toggleAcquirers('dna', e.currentTarget.checked)}
                            checked={acquirers.dna}
                            disabled={
                                dnaApplicationId &&
                                acquirers.dna &&
                                dnaAccount.applicationStatus !== DNAEventStatus.NEED_ADDITIONAL_INFO
                            }
                        />
                        <span>DNA</span>
                    </div>
                    {/*  <div>
                        <input
                            className={styles.acquirerInput}
                            type="checkbox"
                            onChange={(e) => toggleAcquirers('obn', e.currentTarget.checked)}
                            checked={acquirers.obn}
                        />
                        <span>OBN</span>
                    </div> */}
                    <div>
                        <input
                            className={styles.acquirerInput}
                            type="checkbox"
                            disabled
                            onChange={(e) => toggleAcquirers('barclays', e.currentTarget.checked)}
                            checked={acquirers.barclays}
                        />
                        <span>Barclays</span>
                    </div>
                    <div className={styles.acquirerButton}>
                        <Tooltip title="Submit a list of selected Aquirers">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={acquirers.dna && !isDnaDisabled ? openDnaForm : submitPartnerApplication}
                                disabled={
                                    (adyenAccountId && adminFunctions.missingInfoForAdyen?.length === 0) || disable
                                }
                            >
                                Submit
                            </Button>
                        </Tooltip>
                    </div>

                    {adyenAccountId && adminFunctions.missingInfoForAdyen?.length > 0 && (
                        <Typography color="error" style={{ marginTop: '10px', fontSize: '14px' }}>
                            <strong>Missing Information for Adyen</strong>
                            {adminFunctions.missingInfoForAdyen?.map((el, index) => {
                                return ` ${el}${
                                    adminFunctions.missingInfoForAdyen.length - 1 === index
                                        ? '.'
                                        : adminFunctions.missingInfoForAdyen.length - 2 === index
                                        ? ' and '
                                        : ', '
                                }`;
                            })}
                        </Typography>
                    )}

                    {dnaErrorList.map((list) => {
                        return (
                            <ListItem>
                                <Typography color="error" style={{ marginLeft: '10px', fontSize: '14px' }}>
                                    {list}
                                </Typography>
                            </ListItem>
                        );
                    })}
                </Grid>
            </Grid>
            {error ? (
                <Grid container>
                    <Grid item xs={12}>
                        {error ? (
                            <div
                                className={styles.errorText}
                                dangerouslySetInnerHTML={{ __html: `submission Error: ${error}` }}
                            ></div>
                        ) : null}
                    </Grid>
                </Grid>
            ) : null}

            <Grid container className={styles.tables} xs={12} sm={12} md={12}>
                <TableContainer>
                    <Table aria-labelledby="tableTitle" size="small" stickyHeader aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Acquirer</TableCell>
                                <TableCell>Merchant Id</TableCell>
                                <TableCell>Account Holder Id</TableCell>
                                <TableCell>Account status</TableCell>
                                <TableCell>Payout Status</TableCell>
                                <TableCell>Payout Reason</TableCell>
                                <TableCell>Onboarding Errors</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key={document.documentId}>
                                <TableCell>ADYEN</TableCell>
                                <TableCell>{row.merchantId ? row.merchantId : <span>&mdash;</span>}</TableCell>
                                <TableCell>
                                    {row.adyenSubAccountId ? row.adyenSubAccountId : <span>&mdash;</span>}
                                </TableCell>
                                <TableCell>{row.accountStatus ? row.accountStatus : <span>&mdash;</span>}</TableCell>
                                <TableCell>{row.payoutStatus ? row.payoutStatus : <span>&mdash;</span>}</TableCell>

                                <TableCell>
                                    {JSON.stringify(row.reason) === '{}' || !row.reason ? (
                                        <span>&mdash;</span>
                                    ) : (
                                        <>
                                            {row.isBalanceAccount ? (
                                                <>
                                                    <Typography
                                                        style={{
                                                            marginLeft: '15px',
                                                            marginBottom: '10px',
                                                            fontSize: '14px'
                                                        }}
                                                    >
                                                        {JSON.stringify(row.reason) === '{}' || !row.reason ? (
                                                            <strong>Please provide the below information</strong>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </Typography>
                                                    <ul style={{ color: '#F44336' }}>
                                                        {Object.values(row.reason).map((el) => {
                                                            return el.map((e) => {
                                                                return (
                                                                    <li>
                                                                        <Typography
                                                                            color="error"
                                                                            style={{
                                                                                marginLeft: '5px',
                                                                                fontSize: '14px'
                                                                            }}
                                                                        >
                                                                            {e.messages}
                                                                        </Typography>
                                                                    </li>
                                                                );
                                                            });
                                                        })}
                                                    </ul>
                                                </>
                                            ) : (
                                                <div>
                                                    <pre>{JSON.stringify(row.reason, null, 2)}</pre>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {JSON.stringify(row?.onboardingErrors) === '[]' || !row.reason ? (
                                        <span>&mdash;</span>
                                    ) : (
                                        <>
                                            {row?.isBalanceAccount && row?.onboardingErrors?.length > 0 && (
                                                <>
                                                    {row?.onboardingErrors?.map((el) => {
                                                        return (
                                                            <li style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <Typography
                                                                    style={{ marginBottom: '10px', fontSize: '14px' }}
                                                                >
                                                                    <strong>{el?.name?.split('.')?.join(' ')}</strong>
                                                                </Typography>
                                                                <Typography
                                                                    color="error"
                                                                    style={{ marginLeft: '10px', fontSize: '14px' }}
                                                                >
                                                                    {el?.message}
                                                                </Typography>
                                                            </li>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </>
                                    )}
                                </TableCell>
                                {/* <TableCell>
                                    {isSuperAdmin ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => setAdyenCloseModal(true)}
                                            disabled={
                                                row.accountStatus !== AdyenAccountStatusStringToEnum.Active &&
                                                row.accountStatus !== AdyenAccountStatusStringToEnum.Suspended
                                            }
                                        >
                                            Close
                                        </Button>
                                    ) : (
                                        <span>&mdash;</span>
                                    )}
                                </TableCell> */}
                            </TableRow>
                            <TableRow key={document.documentId}>
                                <TableCell>DNA</TableCell>
                                <TableCell>
                                    {dnaAccount?.merchantId ? dnaAccount?.merchantId : <span>&mdash;</span>}
                                </TableCell>
                                <TableCell>
                                    {dnaAccount.productType ? dnaAccount?.productType : <span>&mdash;</span>}
                                </TableCell>
                                <TableCell>
                                    {dnaAccount.accountStatus ? dnaAccount?.accountStatus : <span>&mdash;</span>}
                                </TableCell>
                                <TableCell>
                                    <span>&mdash;</span>
                                </TableCell>
                                <TableCell>{dnaAccount.reason ? dnaAccount?.reason : <span>&mdash;</span>}</TableCell>
                            </TableRow>
                            <TableRow key={document.documentId}>
                                <TableCell>OBN</TableCell>
                                <TableCell>&mdash;</TableCell>
                                <TableCell>&mdash;</TableCell>
                                <TableCell>&mdash;</TableCell>
                                <TableCell>&mdash;</TableCell>
                                <TableCell>&mdash;</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {openModal && (
                <DnaFormModal
                    open={openModal}
                    setOpen={setOpenModal}
                    merchantId={adminFunctions.merchantId}
                    businessTypeId={adminFunctions.businessTypeId}
                    data={merchantData}
                    setData={setMerchantData}
                    pdqFee={pdqFee}
                    setPdqFee={setPdqFee}
                    onConfirm={onConfirm}
                    onboardedMerchant={adminFunctions.dnaApplicationId ? true : false}
                    isLoading={modalLoading}
                    isAdmin={true}
                />
            )}
            <ModalPDQConfirmation
                setShow={setAdyenCloseModal}
                show={adyenCloseModal}
                confirm={closeAdyenAccount}
                modalLoading={modalLoading}
                dialogText={`Are you sure you want to close the adyen account?`}
            />
            <LoadingScreen open={isLoading} />
            {showMessage && (
                <SnackBarAndAlert
                    open={showMessage}
                    onClose={() => {
                        setShowMessage(false);
                        setMessage('');
                    }}
                    type={messageType}
                >
                    {message}
                </SnackBarAndAlert>
            )}
            {/* <div className={styles.aquirerButton}>
                <Tooltip title="Refresh status">
                    <Button variant="contained" color="primary" onClick={getPartnerApplications}>
                        Refresh Acquirer Status
                    </Button>
                </Tooltip>
            </div> */}
        </>
    );
}
ManagePartnersComponent.propTypes = {
    onChange: PropTypes.func,
    dnaAccount: PropTypes.object,
    dnaApplicationId: PropTypes.string
};
