import { useAdminFunctionsContext, useGlobalStateContext } from 'contexts';
import React, { useEffect, useState } from 'react';
import { AdminService, OtpService, NonResellerMerchantService, AdyenService } from 'services';
import { MerchantAccountStatus } from 'utils/enums/MerchantAccountStatus';
import { maskCharacter } from 'utils/helper';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import AdminFunctionsComponent from './admin-functions.component';
import moment from 'moment';
import { statusCodes } from 'utils/StatusCodes';
import { REACT_APP_SIGNUP_LINK_REACTIVATION_PERIOD } from 'utils/constants';

export default function AdminFunctions(props) {
    const { adminFunctions, setAdminFunctions } = useAdminFunctionsContext();
    const { globalState, getRoute } = useGlobalStateContext();
    const [isLoading, setIsLoading] = useState(false);
    const [adyenLoader, setAdyenLoader] = useState(false);
    const [error, setError] = useState(false);
    const isSubmitted = sessionStorage.getItem('isUserSubmitted');
    const [clientRegistered, setClientRegistered] = useState(false);
    const [resellerCreation, setResellerCreation] = useState(false);
    const [merchantVerificationAcceptRejectMessage, setMerchantVerificationAcceptRejectMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [messageType, setMessageType] = useState('');
    const [acceptTermsAndConditionsMessage, setAcceptTermsAndConditionsMessage] = useState('');
    const [showAcceptTermsAndConditionsMessage, setShowAcceptTermsAndConditionsMessage] = useState(false);
    const [orangeMessageStyle, setOrangeMessageStyle] = useState(false);
    const [showCreateFeeTier, setShowCreateFeeTier] = useState(false);
    const [feeTier, setFeeTier] = useState('');
    const [pricingTiers, setPricingTiers] = useState([]);
    const [message, setMessage] = useState('');
    const [showFeeTierMessage, setShowFeeTierMessage] = useState(false);
    const [updateFeeTier, setUpdateFeeTier] = useState('');
    const [isModalUpdateFeeTier, setIsModalUpdateFeeTier] = useState(false);
    const [updateFeeTierValue, setUpdateFeeTierValue] = useState('');
    const [updateFeeTierMessage, setUpdateFeeTierMessage] = useState('');
    const [showUpdateFeeTierMessage, setShowUpdateFeeTierMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [BalanceReportMessage, SetBalanceReportMesssage] = useState(false);
    const [balanceReportModal, SetBalanceReportModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [openModalOtpVerification, setOpenModalOtpVerification] = useState(false);
    const [showMerchantVerificationMessage, setShowMerchantVerificationMessage] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otpError, setOtpError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [snackbarDetails, setSnackbarDetails] = useState({ type: '', message: '' });
    const [linkStatus, setLinkStatus] = useState(false);
    const [paymentConfig, setPaymentConfig] = useState({
        max_single_txn_limit: 0,
        txnUpperLimit: 0,
        xpress_sale_limit: 0,
        xpressSaleUpperLimit: 0
    });
    const [showSignupModal, setShowSignupModal] = useState(false);
    let supportUtilitiesBaseRoute = getRoute('/support-utilities/');

    const supportUtilitiesLinksMap = {
        updateUserAttributes: supportUtilitiesBaseRoute + 'update-user-attributes',
        addResellerTermsAndConditions: supportUtilitiesBaseRoute + 'add-terms-and-conditions'
    };

    const getCustomerConfig = async () => {
        setLoading(true);
        const response = await AdminService.GetMerchantDetails();

        if (response.isSuccesfully === true) {
            const data = {
                max_single_txn_limit: response.data.data.max_single_txn_limit,
                txnUpperLimit: response.data.data.single_txn_upper_limit,
                xpress_sale_limit: response.data.data.xpress_sale_limit,
                xpressSaleUpperLimit: response.data.data.xpress_sale_upper_limit
            };
            setPaymentConfig(data);
        } else {
            setMessageType('error');
            setMessage('An error occurred while loading Merchant Config');
        }
        setLoading(false);
    };
    useEffect(() => {
        const getPricingTiers = async () => {
            setIsLoading(true);
            const response = await AdminService.getFeeTierNames(globalState?.merchant?.id);
            setIsLoading(false);
            if (response.isSuccesfully === true && !response.data.errorMessage) {
                const customerTierId = response.data?.data?.customerFeetierId;
                const feeTier = response.data?.data?.feeTierNames?.filter((feeTier) => feeTier.id === customerTierId);
                setPricingTiers(response.data?.data?.feeTierNames);
                setFeeTier(feeTier[0]?.id);
                setUpdateFeeTier(feeTier[0]);
            } else {
                setMessageType('error');
                setMessage('An error occurred while loading FeeTierNames');
            }
        };
        getPricingTiers();
        getCustomerConfig();
        // eslint-disable-next-line
    }, [globalState?.merchant?.id]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            if (props?.match?.params?.merchantId) {
                const merchantData = await AdminService.GetMerchantDataForAdmin(props.match.params.merchantId);
                if (merchantData.isSuccesfully) {
                    switch (merchantData.data.status) {
                        case MerchantStatus.MERCHANT_PENDING:
                            merchantData.data.accountStatus = MerchantAccountStatus.PENDING;
                            break;
                        case MerchantStatus.PENDING_STRIPE:
                            merchantData.data.accountStatus = MerchantAccountStatus.PENDING_STRIPE;
                            break;
                        case MerchantStatus.ACTIVE:
                            merchantData.data.accountStatus = MerchantAccountStatus.ACTIVE;
                            break;
                        case MerchantStatus.CLOSED:
                            merchantData.data.accountStatus = MerchantAccountStatus.CLOSED;
                            break;
                        case MerchantStatus.REJECTED:
                            merchantData.data.accountStatus = MerchantAccountStatus.REJECTED;
                            break;
                        case MerchantStatus.WATCHLIST:
                            merchantData.data.accountStatus = MerchantAccountStatus.WATCHLIST;
                            break;
                        case MerchantStatus.NOT_SUBMITTED:
                            merchantData.data.accountStatus = MerchantAccountStatus.NOT_SUBMITTED;
                            break;
                        default:
                            merchantData.data.accountStatus = MerchantAccountStatus.PENDING;
                            break;
                    }
                    setAdminFunctions(merchantData.data);
                    setError(false);
                } else {
                    setError(true);
                }
            }
            setIsLoading(false);
        };
        fetchData();

        // eslint-disable-next-line
    }, [props?.match?.params?.merchantId]);

    useEffect(() => {
        if (isSubmitted) {
            setClientRegistered(true);
            sessionStorage.removeItem('isUserSubmitted');
        }
    }, [isSubmitted]);

    useEffect(() => {
        const show = sessionStorage.getItem('resellerCreationSuccess');
        if (show) {
            setResellerCreation(true);
            sessionStorage.removeItem('resellerCreationSuccess');
        }
    }, []);

    const acceptTermsAndConditions = async () => {
        setIsLoading(true);
        const response = await AdminService.acceptTermsAndConditions(adminFunctions.stripeId, globalState.merchant.id);
        setIsLoading(false);
        setShowAcceptTermsAndConditionsMessage(true);
        if (response.isSuccesfully) {
            setMessageType('success');
            if (response.data.requirement) {
                setOrangeMessageStyle(true);
                setAcceptTermsAndConditionsMessage(response.data.requirement);
            } else {
                setAcceptTermsAndConditionsMessage('Terms and condition accepted');
            }
        } else {
            setMessageType('error');
            setAcceptTermsAndConditionsMessage(response?.error);
        }
    };

    const handleOnChange = async (value) => {
        setLoading(true);
        setFeeTier(value);
        setShowUpdateFeeTierMessage(true);
        const response = await AdminService.updateFeeTierId(globalState.merchant?.id, value);
        if (response.isSuccesfully) {
            setMessageType('success');
            setUpdateFeeTierMessage('Fee tier update successfully');
        } else {
            setMessageType('error');
            setAcceptTermsAndConditionsMessage(response?.error);
        }
        setLoading(false);
        setShowUpdateFeeTierMessage(false);
    };
    const SubmitReport = async () => {
        setIsLoading(true);
        const payload = {
            email: globalState?.user?.email,
            month: moment(selectedDate, 'YYYY-MM-DD').month() + 1,
            year: moment(selectedDate, 'YYYY-MM-DD').year()
        };
        const response = await AdminService.GetBalanceReport(payload);
        if (response.isSuccesfully) {
            setMessageType('success');
            setMessage(response.data.message);
            SetBalanceReportModal(false);
        } else {
            setMessageType('error');
            setMessage('No transaction found for this month');
            SetBalanceReportModal(false);
        }
        SetBalanceReportMesssage(true);
        setIsLoading(false);
        setSelectedDate(new Date());
    };
    const cancelAction = () => {
        setSelectedDate(new Date());
    };

    const sendOtp = async () => {
        setOpenModalOtpVerification(false);
        setIsLoading(true);
        const response = await OtpService.createOtp(globalState.merchant?.id, {
            method: 'phone',
            type: 'merchant-verification',
            portalURL: globalState.reseller?.portalURL
        });
        if (response.isSuccesfully) {
            setPhoneNumber(maskCharacter(response.data.data));
            setOpenModalOtpVerification(true);
        } else {
            setOtpError(response.data);
        }
        setIsLoading(false);
    };

    const handleRenewSignupLink = async () => {
        try {
            const { data } = await NonResellerMerchantService.RenewSignupLink(globalState.merchant?.id);
            const isSuccess = data?.statusCode === statusCodes.REACTIVATING_SIGNUP_LINK().statusCode;

            setSnackbarDetails({
                type: isSuccess ? 'success' : 'error',
                message: isSuccess
                    ? statusCodes.REACTIVATING_SIGNUP_LINK(Number(REACT_APP_SIGNUP_LINK_REACTIVATION_PERIOD)).message
                    : statusCodes.SIGNUP_LINK_REACTIVATION_UNAVAILABLE.message
            });
        } catch (error) {
            console.error('~~ Reactivation failed: ~~', error);
        }
    };

    const handleAdyenOnboardingLink = async () => {
        try {
            setAdyenLoader(true);
            const response = await AdyenService.adyenOnboardingShareableLink(globalState?.merchant?.id);
            if (response.isSuccesfully) {
                setAdyenLoader(false);
                await navigator.clipboard.writeText(response.data);
                setSnackbarDetails({
                    type: 'success',
                    message: 'Adyen onboarding link successfully sent to the merchant owner'
                });
            } else {
                setAdyenLoader(false);
                setSnackbarDetails({
                    type: 'error',
                    message: 'Not a valid merchantId'
                });
            }
        } catch (error) {
            setAdyenLoader(false);
            setSnackbarDetails({
                type: 'error',
                message: 'Not a valid merchantId'
            });
            console.error('~~ Coping Adyen Onboarding Link failed ~~', error);
        }
    };

    return (
        <AdminFunctionsComponent
            {...props}
            supportUtilitiesLinksMap={supportUtilitiesLinksMap}
            isLoading={isLoading}
            error={error}
            setError={setError}
            clientRegistered={clientRegistered}
            setClientRegistered={setClientRegistered}
            merchantVerificationAcceptRejectMessage={merchantVerificationAcceptRejectMessage}
            setMerchantVerificationAcceptRejectMessage={setMerchantVerificationAcceptRejectMessage}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            messageType={messageType}
            setMessageType={setMessageType}
            resellerCreation={resellerCreation}
            setResellerCreation={setResellerCreation}
            acceptTermsAndConditions={acceptTermsAndConditions}
            setShowAcceptTermsAndConditionsMessage={setShowAcceptTermsAndConditionsMessage}
            acceptTermsAndConditionsMessage={acceptTermsAndConditionsMessage}
            showAcceptTermsAndConditionsMessage={showAcceptTermsAndConditionsMessage}
            setOrangeMessageStyle={setOrangeMessageStyle}
            orangeMessageStyle={orangeMessageStyle}
            setShowCreateFeeTier={setShowCreateFeeTier}
            showCreateFeeTier={showCreateFeeTier}
            feeTier={feeTier}
            setFeeTier={setFeeTier}
            pricingTiers={pricingTiers}
            setPricingTiers={setPricingTiers}
            message={message}
            setMessage={setMessage}
            setShowFeeTierMessage={setShowFeeTierMessage}
            showFeeTierMessage={showFeeTierMessage}
            updateFeeTier={updateFeeTier}
            isModalUpdateFeeTier={isModalUpdateFeeTier}
            setIsModalUpdateFeeTier={setIsModalUpdateFeeTier}
            handleOnChange={handleOnChange}
            setUpdateFeeTierValue={setUpdateFeeTierValue}
            updateFeeTierValue={updateFeeTierValue}
            updateFeeTierMessage={updateFeeTierMessage}
            showUpdateFeeTierMessage={showUpdateFeeTierMessage}
            setShowUpdateFeeTierMessage={setShowUpdateFeeTierMessage}
            loading={loading}
            SubmitReport={SubmitReport}
            BalanceReportMessage={BalanceReportMessage}
            SetBalanceReportMesssage={SetBalanceReportMesssage}
            balanceReportModal={balanceReportModal}
            SetBalanceReportModal={SetBalanceReportModal}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setOpenModalOtpVerification={setOpenModalOtpVerification}
            openModalOtpVerification={openModalOtpVerification}
            sendOtp={sendOtp}
            phoneNumber={phoneNumber}
            otpError={otpError}
            setOtpError={setOtpError}
            showMerchantVerificationMessage={showMerchantVerificationMessage}
            setShowMerchantVerificationMessage={setShowMerchantVerificationMessage}
            cancelAction={cancelAction}
            showModal={showModal}
            setShowModal={setShowModal}
            snackbarDetails={snackbarDetails}
            setSnackbarDetails={setSnackbarDetails}
            paymentConfig={paymentConfig}
            setLoading={setLoading}
            setPaymentConfig={setPaymentConfig}
            handleRenewSignupLink={handleRenewSignupLink}
            showSignupModal={showSignupModal}
            setShowSignupModal={setShowSignupModal}
            handleAdyenOnboardingLink={handleAdyenOnboardingLink}
            adyenLoader={adyenLoader}
        />
    );
}
