import React, { useState, useEffect } from 'react';
import {
    NonResellerMerchantService,
    TaxonomyService,
    DocumentsService
    //NationalityService
} from 'services';
import { ProductRequirme } from 'models';
import { useGlobalStateContext } from 'contexts/global-state/global-state.context';
import moment from 'moment';
import nonResellerMerchantService from 'services/non-reseller.merchant.service';
import { getFormattedDocumentsStatsForMerchant } from 'utils/helper';
import { DocumentFlag } from 'utils/enums/DocumentFlag';
import { UserType } from 'utils/enums/UserType';
const imgPaths = [
    require('../../assets/icons/gateway.svg'),
    require('../../assets/icons/my-pay-app.svg'),
    require('../../assets/icons/virtual-terminal.png'),
    require('../../assets/icons/my-ecomm.svg'),
    require('../../assets/icons/card-machine.svg')
];

const OnboardingFormContext = React.createContext();

function OnboardingFormProvider(props) {
    const [oldSortCode, setOldSortCode] = useState('');
    const [oldAccountNumber, setOldAccountNumber] = useState('');
    const { globalState } = useGlobalStateContext();
    const isAdmin =
        globalState.user.UserType.name === UserType.Admin || globalState.user.UserType.name === UserType.SuperAdmin;
    let completedSteps = {};

    if (globalState.isT2SMerchant) {
        completedSteps = {
            nameAndAddress: false,
            bankDetails: false,
            tradingAddress: false,
            ownerDetails: false,
            uploadDocuments: false,
            summary: false
        };
    } else {
        completedSteps = {
            nameAndAddress: false,
            businessDetails: false,
            tradingAddress: false,
            ownerDetails: false,
            businessProfile: false,
            transactionProfile: false,
            productsRequired: false,
            uploadDocuments: false,
            summary: false
        };
    }

    const initialState = {
        isPopulated: false,
        nameAndAddress: {
            companies: [],
            legalName: globalState.merchant?.name || ''
        },
        businessTypes: [],
        businessDetails: {
            businessTypeId: 1,
            employeeIdNumber: '',
            email: '',
            phoneNumber: '',
            websiteUrl: '',
            businessCategory: ''
        },
        tradingAddress: {
            sortCode: '',
            newAccountNumber: '',
            routingNumber: '',
            bsb: '',
            transitNumber: '',
            financialInstitutionNumber: '',
            isValidated: false,
            isSameAsBusinessAddress: false,
            accountHolderName: globalState.merchant?.name,
            state: ''
        },
        ownersDetails: {
            title: 'Mr',
            persons: [],
            nationality: '--Please select--',
            dateOfBirth: { day: '', month: '', year: '' },
            ownership: 25,
            isOwnershipRange: false,
            ssnLastDigits: '',
            fullName: '',
            email: '',
            contactTel: '',
            businessTitle: 'Director',
            isSameAsBusinessEmail: false,
            isSameAsBusinessPhoneNumber: false,
            firstName: globalState?.user?.firstName,
            lastName: globalState?.user?.lastName,
            tinNumber: ''
        },
        nationalities: [
            '--Please select--',
            'Afghan',
            'Albanian',
            'Algerian',
            'American',
            'Andorran',
            'Angolan',
            'Anguillan',
            'Argentine',
            'Armenian',
            'Australian',
            'Austrian',
            'Azerbaijani',
            'Bahamian',
            'Bahraini',
            'Bangladeshi',
            'Barbadian',
            'Belarusian',
            'Belgian',
            'Belizean',
            'Beninese',
            'Bermudian',
            'Bhutanese',
            'Bolivian',
            'Botswanan',
            'Brazilian',
            'British',
            'British Virgin Islander',
            'Bruneian',
            'Bulgarian',
            'Burkinan',
            'Burmese',
            'Burundian',
            'Cambodian',
            'Cameroonian',
            'Canadian',
            'Cape Verdean',
            'Cayman Islander',
            'Central African',
            'Chadian',
            'Chilean',
            'Chinese',
            'Citizen of Antigua and Barbuda',
            'Citizen of Bosnia and Herzegovina',
            'Citizen of Guinea-Bissau',
            'Citizen of Kiribati',
            'Citizen of Seychelles',
            'Citizen of the Dominican Republic',
            'Citizen of Vanuatu',
            'Colombian',
            'Comoran',
            'Congolese (Congo)',
            'Congolese (DRC)',
            'Cook Islander',
            'Costa Rican',
            'Croatian',
            'Cuban',
            'Cymraes',
            'Cymro',
            'Cypriot',
            'Czech',
            'Danish',
            'Djiboutian',
            'Dominican',
            'Dutch',
            'East Timorese',
            'Ecuadorean',
            'Egyptian',
            'Emirati',
            'English',
            'Equatorial Guinean',
            'Eritrean',
            'Estonian',
            'Ethiopian',
            'Faroese',
            'Fijian',
            'Filipino',
            'Finnish',
            'French',
            'Gabonese',
            'Gambian',
            'Georgian',
            'German',
            'Ghanaian',
            'Gibraltarian',
            'Greek',
            'Greenlandic',
            'Grenadian',
            'Guamanian',
            'Guatemalan',
            'Guinean',
            'Guyanese',
            'Haitian',
            'Honduran',
            'Hong Konger',
            'Hungarian',
            'Icelandic',
            'Indian',
            'Indonesian',
            'Iranian',
            'Iraqi',
            'Irish',
            'Israeli',
            'Italian',
            'Ivorian',
            'Jamaican',
            'Japanese',
            'Jordanian',
            'Kazakh',
            'Kenyan',
            'Kittitian',
            'Kosovan',
            'Kuwaiti',
            'Kyrgyz',
            'Lao',
            'Latvian',
            'Lebanese',
            'Liberian',
            'Libyan',
            'Liechtenstein citizen',
            'Lithuanian',
            'Luxembourger',
            'Macanese',
            'Macedonian',
            'Malagasy',
            'Malawian',
            'Malaysian',
            'Maldivian',
            'Malian',
            'Maltese',
            'Marshallese',
            'Martiniquais',
            'Mauritanian',
            'Mauritian',
            'Mexican',
            'Micronesian',
            'Moldovan',
            'Monegasque',
            'Mongolian',
            'Montenegrin',
            'Montserratian',
            'Moroccan',
            'Mosotho',
            'Mozambican',
            'Namibian',
            'Nauruan',
            'Nepalese',
            'New Zealander',
            'Nicaraguan',
            'Nigerian',
            'Nigerien',
            'Niuean',
            'North Korean',
            'Northern Irish',
            'Norwegian',
            'Omani',
            'Pakistani',
            'Palauan',
            'Palestinian',
            'Panamanian',
            'Papua New Guinean',
            'Paraguayan',
            'Peruvian',
            'Pitcairn Islander',
            'Polish',
            'Portuguese',
            'Prydeinig',
            'Puerto Rican',
            'Qatari',
            'Romanian',
            'Russian',
            'Rwandan',
            'Salvadorean',
            'Sammarinese',
            'Samoan',
            'Sao Tomean',
            'Saudi Arabian',
            'Scottish',
            'Senegalese',
            'Serbian',
            'Sierra Leonean',
            'Singaporean',
            'Slovak',
            'Slovenian',
            'Solomon Islander',
            'Somali',
            'South African',
            'South Korean',
            'South Sudanese',
            'Spanish',
            'Sri Lankan',
            'St Helenian',
            'St Lucian',
            'Stateless',
            'Sudanese',
            'Surinamese',
            'Swazi',
            'Swedish',
            'Swiss',
            'Syrian',
            'Taiwanese',
            'Tajik',
            'Tanzanian',
            'Thai',
            'Togolese',
            'Tongan',
            'Trinidadian',
            'Tristanian',
            'Tunisian',
            'Turkish',
            'Turkmen',
            'Turks and Caicos Islander',
            'Tuvaluan',
            'Ugandan',
            'Ukrainian',
            'Uruguayan',
            'Uzbek',
            'Vatican citizen',
            'Venezuelan',
            'Vietnamese',
            'Vincentian',
            'Wallisian',
            'Welsh',
            'Yemeni',
            'Zambian',
            'Zimbabwean'
        ],
        ownerPrimaryAddress: {
            isSameAsTradingAddress: 'false'
        },
        businessDescriptions: [],
        companies: [],
        businessProfile: {
            isBusinessMakingProducts: false,
            isStockSufficient: false,
            businessDescriptions: [],
            productDescription: null
        },
        motoRenewalReasonList: [],
        transactionProfile: {
            stepOne: {
                isDepositsTaken: false,
                goods: 0,
                cardTurnover: 0,
                depositFarDays: 1,
                noDeliveryDays: 1,
                isPrePayment: true,
                fullPrePayments: 0,
                advanceFullPaymentsDays: 1
            },
            stepTwo: {
                companyTurnOverActual: '',
                companyTurnOverProjected: '',
                cardTurnOverActual: '',
                cardTurnOverProjected: '',
                priceRangeMin: '',
                priceRangeMax: '',
                priceRangeAvg: ''
            },
            stepThree: {
                isMotoPayment: false,
                isMaxTicketApplied: false,
                totalCardTurnoverIsMoto: 0,
                advanceGoodsMotoProvidedDays: 1,
                isAutoRenewTransactions: false
            }
        },
        productsRequired: [],
        selectedProducts: [],
        loading: true,
        completedSteps: completedSteps,
        validOnboardingFrom: true
    };

    const [onboardingForm, setOnboardingForm] = useState(initialState);

    useEffect(() => {
        const fetchData = async () => {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm
            }));

            const [products, descriptions] = await Promise.all([
                initProducts(),
                initDescriptions(),
                initDataBusinessTypes()
            ]);
            await initMerchantsFullData(products, descriptions);
            if (globalState.isT2SMerchant) {
                await initCompletedSteps();
            } else {
                setOnboardingForm((onboardingForm) => ({
                    ...onboardingForm,
                    completedSteps: {
                        ...onboardingForm.completedSteps,
                        nameAndAddress: globalState.merchant.onboardingStep > 1,
                        businessDetails: globalState.merchant.onboardingStep > 2,
                        tradingAddress: globalState.merchant.onboardingStep > 3,
                        ownerDetails: globalState.merchant.onboardingStep > 4,
                        businessProfile: globalState.merchant.onboardingStep > 5,
                        transactionProfile: globalState.merchant.onboardingStep > 6,
                        productsRequired: globalState.merchant.onboardingStep > 7,
                        uploadDocuments: globalState.merchant.onboardingStep > 8
                    }
                }));
            }

            //TODO: Uncomment this and make sure the value from Company lookup is pre-populated correctly
            // NationalityService.getNationalities().then((response) => {
            //     if (response.isSuccesfully) {
            //         console.log(response.data);
            //         setOnboardingForm((onboardingForm) => ({
            //             ...onboardingForm,
            //             nationalities: response.data
            //         }));
            //     }
            // });

            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                loading: false
            }));

            setOldAccountNumber(onboardingForm.tradingAddress?.newAccountNumber);
            setOldSortCode(onboardingForm.tradingAddress?.sortCode);
        };

        fetchData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const validateFrom = () => {
            const completedSteps = Object.values(onboardingForm.completedSteps);
            completedSteps.pop();
            const isValid = completedSteps.reduce((step1, step2) => step1 && step2);
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                validOnboardingFrom: isValid
            }));
        };
        validateFrom();
    }, [onboardingForm.completedSteps]);

    const initCompletedSteps = async () => {
        const completedStepsResponse = await nonResellerMerchantService.t2sIncompleteOnBoardingFormCheck(
            globalState.merchant?.id
        );

        let noDocumentsPending = false;
        const documentsResponse = await DocumentsService.listObjects(
            'merchant',
            globalState.merchant?.id,
            DocumentFlag.ONBOARDING
        );

        if (completedStepsResponse.isSuccesfully) {
            setOnboardingForm((onboardingForm) => {
                if (documentsResponse.isSuccesfully) {
                    noDocumentsPending = getFormattedDocumentsStatsForMerchant({
                        merchantBusinessTypeId: onboardingForm.businessDetails.businessTypeId,
                        merchantCountry: globalState.merchant?.country,
                        merchantDocuments: documentsResponse.data
                    }).hasAllMandatoryDocsUploaded;
                }

                return {
                    ...onboardingForm,
                    validOnboardingFrom: completedStepsResponse.data.validOnboardingFrom,
                    completedSteps: {
                        ...onboardingForm.completedSteps,
                        nameAndAddress: completedStepsResponse.data.nameAndAddressIncompleteFields.length === 0,
                        bankDetails: completedStepsResponse.data.bankDetailsIncompleteFields.length === 0,
                        tradingAddress: completedStepsResponse.data.tradingAddressIncompleteFields.length === 0,
                        ownerDetails: completedStepsResponse.data.ownerDetailsIncompleteFields.length === 0,
                        uploadDocuments: onboardingForm.completedSteps.uploadDocuments || noDocumentsPending
                    }
                };
            });
        }
    };

    const initDataBusinessTypes = async () => {
        const businessTypes = await TaxonomyService.getBusinessTypes();
        if (businessTypes.isSuccesfully) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                businessTypes: businessTypes.data
            }));
        } else {
            return;
        }
    };

    const initDescriptions = async () => {
        var descriptions;
        const descriptionsFetchResponse = await TaxonomyService.getDescriptions();
        if (descriptionsFetchResponse.isSuccesfully) {
            descriptions = descriptionsFetchResponse.data;
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                businessDescriptions: descriptions.businessDescriptions
            }));
        }

        return descriptions;
    };

    const initProducts = async () => {
        var products;
        const productsRequest = await TaxonomyService.getProductsRequired();
        if (productsRequest.isSuccesfully) {
            products = productsRequest.data;
            products.forEach((element, index) => {
                element.imgPath = imgPaths[index];
            });
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                productsRequired: products
            }));
        }

        return products;
    };

    const initMerchantsFullData = async (products, descriptions) => {
        const merchantResponse = await NonResellerMerchantService.getOnboardingFullData(globalState.merchant?.id);
        if (merchantResponse.isSuccesfully) {
            const merchantData = merchantResponse.data.merchantData;
            const isT2SMerchant = globalState.isT2SMerchant;

            if (merchantData.nameAndAddress && merchantData.tradingAddress) {
                const { nameAndAddress, tradingAddress } = merchantData;
                const isSameAsBusinessAddress =
                    nameAndAddress.postCode === tradingAddress.postCode &&
                    nameAndAddress.addressLine1 === tradingAddress.addressLine1 &&
                    nameAndAddress.addressLine2 === tradingAddress.addressLine2 &&
                    nameAndAddress.state === tradingAddress.state &&
                    nameAndAddress.city === tradingAddress.city;

                merchantData.tradingAddress.isSameAsBusinessAddress = isSameAsBusinessAddress;
            }

            if (merchantData.tradingAddress && !merchantData.tradingAddress?.accountHolderName) {
                merchantData.tradingAddress.accountHolderName = globalState.merchant?.name;
            }

            if (isT2SMerchant && merchantData.businessDetails && merchantData.ownersDetails) {
                const { ownersDetails, businessDetails } = onboardingForm;
                const isSameAsBusinessPhoneNumber = ownersDetails.contactTel === businessDetails.phoneNumber;
                const isSameAsBusinessEmail = ownersDetails.email === businessDetails.email;
                merchantData.ownersDetails.isSameAsBusinessPhoneNumber = isSameAsBusinessPhoneNumber;
                merchantData.ownersDetails.isSameAsBusinessEmail = isSameAsBusinessEmail;
            }

            if (isT2SMerchant && merchantData.tradingAddress && merchantData.ownerPrimaryAddress) {
                const { ownerPrimaryAddress, tradingAddress } = onboardingForm;
                const isSameAsTradingAddress =
                    ownerPrimaryAddress.postCode === tradingAddress.postCode &&
                    ownerPrimaryAddress.addressLine1 === tradingAddress.addressLine1 &&
                    ownerPrimaryAddress.addressLine2 === tradingAddress.addressLine2 &&
                    ownerPrimaryAddress.city === tradingAddress.city &&
                    ownerPrimaryAddress.state === tradingAddress.state &&
                    ownerPrimaryAddress.country === tradingAddress.country;
                merchantData.ownerPrimaryAddress.isSameAsTradingAddress = isSameAsTradingAddress;
            }

            if (merchantData.businessProfile) {
                if (merchantData.businessProfile.startedBusinessAt) {
                    const MONTHS_IN_A_YEAR = 12;
                    merchantData.businessProfile.timeInBusinessYears = moment().diff(
                        moment(merchantData.businessProfile.startedBusinessAt),
                        'years',
                        false
                    );
                    merchantData.businessProfile.timeInBusinessMonths =
                        moment().diff(moment(merchantData.businessProfile.startedBusinessAt), 'months', false) %
                        MONTHS_IN_A_YEAR;

                    merchantData.businessProfile.timeInBusinessYears = merchantData.businessProfile.timeInBusinessYears.toString();
                    merchantData.businessProfile.timeInBusinessMonths = merchantData.businessProfile.timeInBusinessMonths.toString();
                }

                if (descriptions) {
                    merchantData.businessProfile.businessDescriptions = descriptions.businessDescriptions.filter((pd) =>
                        merchantData.businessProfile.businessDescriptions.includes(pd.id)
                    );
                } else {
                    merchantData.businessProfile.businessDescriptions = [];
                }
            }

            if (merchantData.transactionProfile) {
                merchantData.transactionProfile.stepThree.isMotoPayment =
                    merchantData.transactionProfile.stepThree.isMotoPayment ??
                    onboardingForm.transactionProfile.stepThree.isMotoPayment;
                merchantData.transactionProfile.stepThree.isMaxTicketApplied =
                    merchantData.transactionProfile.stepThree.isMaxTicketApplied ??
                    onboardingForm.transactionProfile.stepThree.isMaxTicketApplied;
                merchantData.transactionProfile.stepThree.totalCardTurnoverIsMoto =
                    merchantData.transactionProfile.stepThree.totalCardTurnoverIsMoto ??
                    onboardingForm.transactionProfile.stepThree.totalCardTurnoverIsMoto;
                merchantData.transactionProfile.stepThree.advanceGoodsMotoProvidedDays =
                    merchantData.transactionProfile.stepThree.advanceGoodsMotoProvidedDays ??
                    onboardingForm.transactionProfile.stepThree.advanceGoodsMotoProvidedDays;
                merchantData.transactionProfile.stepThree.isAutoRenewTransactions =
                    merchantData.transactionProfile.stepThree.isAutoRenewTransactions ??
                    onboardingForm.transactionProfile.stepThree.isAutoRenewTransactions;
                merchantData.transactionProfile.stepThree.motoRenewalReason =
                    merchantData.transactionProfile.stepThree.motoRenewalReason ??
                    onboardingForm.transactionProfile.stepThree.motoRenewalReason;
            }

            if (products && merchantData.selectedProducts.length === 0) {
                const gatewayProduct = products.find((product) => product.name === ProductRequirme.Gateway);
                if (gatewayProduct) {
                    merchantData.selectedProducts = [gatewayProduct.id];
                }
            }

            if (merchantData.ownersDetails) {
                merchantData.ownersDetails.persons = [];
                if (merchantData.ownersDetails.dateOfBirth) {
                    const birthDate = merchantData.ownersDetails.dateOfBirth.split('-');
                    merchantData.ownersDetails.dateOfBirth = {
                        day: birthDate[2],
                        month: birthDate[1],
                        year: birthDate[0]
                    };
                } else {
                    merchantData.ownersDetails.dateOfBirth = { day: '', month: '', year: '' };
                }
                merchantData.ownersDetails.ownership = merchantData.ownersDetails.ownership
                    ? merchantData.ownersDetails.ownership
                    : 25;
                merchantData.ownersDetails.businessTitle = merchantData.ownersDetails.businessDetails
                    ? merchantData.ownersDetails.businessDetails
                    : 'Director';

                merchantData.ownersDetails.title = merchantData.ownersDetails.title
                    ? merchantData.ownersDetails.title
                    : onboardingForm.ownersDetails.title;

                merchantData.ownersDetails.ssnLastDigits = merchantData.ownersDetails.ssnLastDigits
                    ? merchantData.ownersDetails.ssnLastDigits
                    : onboardingForm.ownersDetails.ssnLastDigits;

                merchantData.ownersDetails.nationality = merchantData.ownersDetails?.nationality
                    ? merchantData.ownersDetails?.nationality
                    : onboardingForm.ownersDetails.nationality;
            }

            if (merchantData.businessDetails) {
                merchantData.businessDetails.phoneNumber = merchantData.businessDetails.phoneNumber
                    ? merchantData.businessDetails.phoneNumber
                    : onboardingForm.businessDetails.phoneNumber;
                merchantData.businessDetails.email = merchantData.businessDetails.email
                    ? merchantData.businessDetails.email
                    : onboardingForm.businessDetails.email;
                merchantData.businessDetails.businessTypeId = merchantData.businessDetails.businessTypeId
                    ? merchantData.businessDetails.businessTypeId
                    : onboardingForm.businessDetails.businessTypeId;
            }

            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                isPopulated: true,
                nameAndAddress: merchantData.nameAndAddress
                    ? merchantData.nameAndAddress
                    : onboardingForm.nameAndAddress,
                businessDetails: merchantData.businessDetails
                    ? merchantData.businessDetails
                    : onboardingForm.businessDetails,
                tradingAddress: merchantData.tradingAddress
                    ? merchantData.tradingAddress
                    : onboardingForm.tradingAddress,
                ownersDetails: merchantData.ownersDetails ? merchantData.ownersDetails : onboardingForm.ownersDetails,
                ownerPrimaryAddress: merchantData.ownerPrimaryAddress
                    ? merchantData.ownerPrimaryAddress
                    : onboardingForm.ownerPrimaryAddress,
                businessProfile: merchantData.businessProfile
                    ? merchantData.businessProfile
                    : onboardingForm.businessProfile,
                transactionProfile: merchantData.transactionProfile
                    ? merchantData.transactionProfile
                    : onboardingForm.transactionProfile,
                selectedProducts: merchantData.selectedProducts
            }));
        }
    };

    return (
        <OnboardingFormContext.Provider
            value={{
                onboardingForm,
                setOnboardingForm,
                oldSortCode,
                setOldSortCode,
                oldAccountNumber,
                setOldAccountNumber
            }}
        >
            {props.children}
        </OnboardingFormContext.Provider>
    );
}

function useOnboardingFormContext() {
    const onboardingFormContext = React.useContext(OnboardingFormContext);
    if (!onboardingFormContext) {
        throw new Error('useOnboardingFormContext must be used within a OnboardingFormProvider');
    }
    return onboardingFormContext;
}

export { OnboardingFormProvider, useOnboardingFormContext };
