import { AuthAxios, BaseAxios } from './interceptor.service.js';
import { SuccesResponse, ErrorResponse } from 'models';
import { AdyenServiceEndpoint } from '../utils/constants';

const submitAdyenOnboarding = async (merchantId) => {
    try {
        const response = await AuthAxios.post(
            `${AdyenServiceEndpoint}/adyen-balance-platform-onboarding/${merchantId}`
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};
const AdyenUploadDocument = async (merchantId, data) => {
    try {
        const response = await AuthAxios.post(
            `${AdyenServiceEndpoint}/adyen-platform-upload-document/${merchantId}`,
            data
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getError = (error) => {
    const message = error.response ? error.response.data : 'An error occurred';
    return new ErrorResponse(message);
};

const getAdyenAccounts = async (merchantId) => {
    try {
        const response = await AuthAxios.get(`${AdyenServiceEndpoint}/adyen-account/get-adyen-account/${merchantId}`);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const closeAdyenAccount = async (merchantId) => {
    try {
        const response = await AuthAxios.post(
            `${AdyenServiceEndpoint}/adyen-account/close-adyen-account/${merchantId}`
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getAdyenResponse = async (merchantId) => {
    try {
        const response = await AuthAxios.get(`${AdyenServiceEndpoint}/get-adyen-response/${merchantId}`);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getBalancePlatformOnboardingUrl = async (merchantId) => {
    try {
        const response = await AuthAxios.get(`${AdyenServiceEndpoint}/get-adyen-onboarding-hosted-form/${merchantId}`);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getAdyenCriticalMerchantsList = async () => {
    try {
        const response = await AuthAxios.get(`${AdyenServiceEndpoint}/get-adyen-blocked-accounts`);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const migrateToAdyenBalancePlatform = async (merchantId, data) => {
    try {
        const response = await BaseAxios.post(`${AdyenServiceEndpoint}/balance-platform/create-account`, data);

        console.log('---', response);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const adyenGetTOSTypes = async (merchantId) => {
    try {
        const response = await AuthAxios.get(`${AdyenServiceEndpoint}/get-terms-of-service-types/${merchantId}`);
        return new SuccesResponse(response.data);
    } catch (error) {
        getError(error);
    }
};

const adyenGetTOSDocuments = async (merchantId, data) => {
    try {
        const response = await AuthAxios.post(
            `${AdyenServiceEndpoint}/get-terms-of-service-documents/${merchantId}`,
            data
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        getError(error);
    }
};

const adyenAcceptedTermsAndConditon = async (merchantId, data) => {
    try {
        const response = await AuthAxios.patch(`${AdyenServiceEndpoint}/terms-of-service-accepted/${merchantId}`, data);
        return new SuccesResponse(response.data);
    } catch (error) {
        getError(error);
    }
};
const adyenOnboardingShareableLink = async (merchantId) => {
    try {
        const response = await AuthAxios.get(
            `${AdyenServiceEndpoint}/get-adyen-shareable-onboarding-link/${merchantId}`
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        getError(error);
    }
};

export default {
    submitAdyenOnboarding,
    getAdyenAccounts,
    getAdyenResponse,
    getAdyenCriticalMerchantsList,
    closeAdyenAccount,
    getBalancePlatformOnboardingUrl,
    migrateToAdyenBalancePlatform,
    adyenGetTOSTypes,
    adyenGetTOSDocuments,
    adyenAcceptedTermsAndConditon,
    AdyenUploadDocument,
    adyenOnboardingShareableLink
};
