import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    IconButton,
    TableFooter,
    TablePagination,
    useMediaQuery
} from '@material-ui/core';
import SettingsBackupRestoreSharpIcon from '@material-ui/icons/SettingsBackupRestoreSharp';
import CloseIcon from '@mui/icons-material/Close';
import TableHeader from '../table-header/table-header.container.js';
import FilterTabs from '../filter-tabs/filter-tabs.container';
import {
    filterRefundDescription,
    formatCurrency,
    formatDateTimeByFormatString,
    getComparator,
    stableSort,
    merchantTypesEnum,
    filterDescription
} from '../../../../utils/helper';
import { PaymentsFilterTabs } from '../../../../utils/enums/PaymentsFilterTabs';
import styles from './payments-list-table.module.scss';
import TransactionRefund from '../../../transaction/transaction-refund/transaction-refund.container';
import TableFooterComponent from '../table-footer/table-footer.component';
import { useGlobalStateContext } from 'contexts/index.js';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry.js';
import _ from 'lodash';
import { UserType } from 'utils/enums/UserType';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import TransactionStatusLabel from '../transaction-status-label/transaction-status-label.container';
import { PaymentStatus } from 'utils/enums/PaymentStatus.js';
import { AcquirersNameMap } from 'utils/enums/Acquirers.js';
import { ModalFormConfirmation } from 'components';
import GitfCardLableChipContainer from '../gift-card-chip/gift-card-lable-chip.conatiner';
import { getChipLabel } from 'utils/getGiftCardStatus.js';
import { PaymentProvider } from 'utils/enums/PaymentProvider.js';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: 'none'
    },
    container: {
        'overflow-y': 'visible'
    },
    table: {
        minWidth: '100%'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    selected: {},
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    checkbox: {
        color: '#8783b5'
    },
    'MuiIconButton-root': {
        padding: '0px 0px'
    }
}));

export default function PaymentsListTableComponent(props) {
    const { globalState } = useGlobalStateContext();
    const classes = useStyles();
    const {
        rows,
        statusFilter,
        setStatusFilter,
        order,
        orderBy,
        loading,
        show,
        setShow,
        showTransactionRefund,
        payment,
        error,
        setPayment,
        isRefundedTransaction,
        formatRefundReasonAndAmountString,
        noTabs,
        setIsRefundHappen,
        showAcquirer,
        page,
        setPage,
        isOmnipay,
        handleClick,
        showMerchantName,
        pagePath,
        successfullTransactions,
        selectedAcquirer,
        clearFilter,
        acquirerName,
        via,
        handleVoidTransaction,
        showCancelButton,
        voidableTransaction,
        showCancelBtn,
        isDateRangeArchived,
        PaymentVisibilityMessage
    } = props;

    let hidePaymentActions = globalState.merchant?.userRole === 'User';
    const isDatman = globalState.reseller?.id === 2;
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isSuperAdmin = globalState.user?.UserType?.name === UserType.SuperAdmin;

    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const hideSensitiveDetails = globalState?.merchant?.merchantType === merchantTypesEnum.BIG_TABLE;
    return (
        <div className={classes.root}>
            {!noTabs && (
                <FilterTabs
                    tabFilterValue={statusFilter}
                    setTabFilterValue={setStatusFilter}
                    tabList={PaymentsFilterTabs}
                />
            )}
            <Paper className={classes.paper}>
                {loading ? (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <div className={styles.paymentsNone}>An error occurred, please try reloading your browser.</div>
                ) : (
                    <React.Fragment>
                        {via === 'year' || via === 'all' ? (
                            <div className={styles.paymentsNone}>To check the transaction please export</div>
                        ) : !rows || rows.length === 0 ? (
                            selectedAcquirer?.length === 0 || selectedAcquirer?.length === acquirerName?.length ? (
                                <div className={styles.paymentsNone}>
                                    {isDateRangeArchived ? `${PaymentVisibilityMessage}` : 'No transactions.'}
                                </div>
                            ) : isAdmin ? (
                                <div className={styles.paymentsNone}>
                                    No results available for this filter.
                                    <span className={styles.filterLink} onClick={clearFilter}>
                                        Remove all filters
                                    </span>
                                </div>
                            ) : (
                                <div className={styles.paymentsNone}>
                                    {isDateRangeArchived ? `${PaymentVisibilityMessage}` : 'No transactions.'}
                                </div>
                            )
                        ) : (
                            <React.Fragment>
                                <TableContainer className={classes.container}>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        aria-label="enhanced table"
                                    >
                                        <TableHeader classes={classes} {...props} />
                                        <TableBody>
                                            {stableSort(
                                                rowsPerPage > 0
                                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : rows,
                                                getComparator(order, orderBy)
                                            ).map((row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                let transactionDetails = hideSensitiveDetails
                                                    ? 'REDACTED'
                                                    : _.compact([
                                                          _.compact([row.firstname?.trim(), row.lastname?.trim()]).join(
                                                              ' '
                                                          ),
                                                          row.address?.trim()
                                                      ]).join(' - ');
                                                let giftCardEnable = _.pick(row, [
                                                    'TxnReference',
                                                    'provider',
                                                    'origin',
                                                    'payment_status',
                                                    'total'
                                                ]);
                                                let chipTexts = getChipLabel(giftCardEnable);
                                                let giftCardExits =
                                                    row.provider === PaymentProvider.GIFT_CARD ||
                                                    giftCardEnable.TxnReference?.startsWith('OGC') ||
                                                    giftCardEnable.TxnReference?.startsWith('GC');

                                                return (
                                                    <React.Fragment>
                                                        <TableRow
                                                            classes={{ selected: classes.selected }}
                                                            className={classes.tableRow + ' ' + styles.tableRow}
                                                            hover
                                                            tabIndex={1}
                                                            key={row.id}
                                                        >
                                                            <TableCell
                                                                id={labelId}
                                                                align="left"
                                                                className={styles.amountColumn}
                                                                style={{ width: '5%' }}
                                                                onClick={() => handleClick(row)}
                                                            >
                                                                {row.refund === '' ? (
                                                                    <React.Fragment>
                                                                        {formatCurrency(
                                                                            row.total,
                                                                            getCurrencyThreeAlpha(
                                                                                globalState.merchant?.country
                                                                            )
                                                                        )}
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <React.Fragment>
                                                                            {formatCurrency(
                                                                                row.total,
                                                                                getCurrencyThreeAlpha(
                                                                                    globalState.merchant?.country
                                                                                )
                                                                            )}
                                                                        </React.Fragment>
                                                                    </React.Fragment>
                                                                )}
                                                            </TableCell>
                                                            {showMerchantName && (
                                                                <TableCell
                                                                    className={styles.dateColumn}
                                                                    style={{ width: '15%' }}
                                                                    onClick={() => handleClick(row)}
                                                                >
                                                                    {row.merchant_name}
                                                                </TableCell>
                                                            )}
                                                            {/* <TableCell
                                                            onClick={() =>
                                                                setRoute(`/payment-details/${row.id}`, {
                                                                    payment: row
                                                                })
                                                            }
                                                        >
                                                            {row.description || '-'}
                                                        </TableCell> */}
                                                            {!mediaLessThan600px && (
                                                                <TableCell
                                                                    className={styles.nameAddressColumn}
                                                                    onClick={() => handleClick(row)}
                                                                >
                                                                    {row.refund ? (
                                                                        <div className={styles.currencySpan}>
                                                                            {formatRefundReasonAndAmountString(
                                                                                row.refund
                                                                            )}
                                                                            {transactionDetails && (
                                                                                <>
                                                                                    <br></br>
                                                                                    <span>{transactionDetails}</span>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div className={styles.currencySpan}>
                                                                            {transactionDetails ? (
                                                                                <span>
                                                                                    {transactionDetails}
                                                                                    {giftCardExits && (
                                                                                        <GitfCardLableChipContainer
                                                                                            status={chipTexts}
                                                                                        />
                                                                                    )}
                                                                                </span>
                                                                            ) : (
                                                                                <span>&#8212;</span>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </TableCell>
                                                            )}
                                                            {!isDatman && (
                                                                <TableCell
                                                                    className={styles.nameAddressColumn}
                                                                    onClick={() => handleClick(row)}
                                                                >
                                                                    {+row.total < 0 ? (
                                                                        <span>
                                                                            {filterRefundDescription(row.refund) || '-'}
                                                                        </span>
                                                                    ) : row.more_info ? (
                                                                        filterDescription(row.more_info)
                                                                    ) : row.description ? (
                                                                        row.description
                                                                    ) : (
                                                                        <span>&mdash;</span>
                                                                    )}
                                                                </TableCell>
                                                            )}
                                                            {showAcquirer && (
                                                                <TableCell
                                                                    className={styles.dateColumn}
                                                                    style={{ width: '15%' }}
                                                                    onClick={() => handleClick(row)}
                                                                >
                                                                    {row.payment_provider}
                                                                </TableCell>
                                                            )}
                                                            <TableCell
                                                                className={styles.dateColumn}
                                                                style={{ width: '15%' }}
                                                                onClick={() => handleClick(row)}
                                                            >
                                                                {formatDateTimeByFormatString(row.time, 'DD MMM HH:mm')}
                                                            </TableCell>
                                                            {!isRefundedTransaction &&
                                                                (hidePaymentActions ||
                                                                row.total <= 0 ||
                                                                row.payed <= 0 ||
                                                                !row.order_id ||
                                                                (row.payment_status
                                                                    ? row.payment_status !== 'OK'
                                                                    : false) ||
                                                                row.payment_provider ===
                                                                    AcquirersNameMap.VOUCHER_CREDIT ||
                                                                row.refund ? (
                                                                    <TableCell
                                                                        className={styles.actionsColumn}
                                                                        align="center"
                                                                    >
                                                                        {isSuperAdmin &&
                                                                            !showCancelButton(
                                                                                row.firstname,
                                                                                row.lastname
                                                                            ) &&
                                                                            voidableTransaction(
                                                                                row.firstname,
                                                                                row.lastname
                                                                            ) &&
                                                                            !showCancelBtn && (
                                                                                <IconButton
                                                                                    className={
                                                                                        classes['MuiIconButton-root']
                                                                                    }
                                                                                    onClick={() => {
                                                                                        showTransactionRefund(row);
                                                                                    }}
                                                                                >
                                                                                    <CloseIcon />
                                                                                </IconButton>
                                                                            )}
                                                                        {payment.id === row.id ? (
                                                                            <ModalFormConfirmation
                                                                                id="submit-confirmation"
                                                                                isModalOpen={show}
                                                                                setIsModalOpen={setShow}
                                                                                confirmationAction={() =>
                                                                                    handleVoidTransaction(row)
                                                                                }
                                                                                buttonText={'Confirm'}
                                                                                modalText="Please confirm that you want to void this transaction"
                                                                            />
                                                                        ) : null}
                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell
                                                                        align="center"
                                                                        className={styles.actionsColumn}
                                                                    >
                                                                        {!giftCardExits && (
                                                                            <IconButton
                                                                                className={
                                                                                    classes['MuiIconButton-root']
                                                                                }
                                                                                onClick={() => {
                                                                                    showTransactionRefund(row);
                                                                                }}
                                                                                disabled={
                                                                                    globalState.merchant.status ===
                                                                                    MerchantStatus.WATCHLIST
                                                                                }
                                                                            >
                                                                                <SettingsBackupRestoreSharpIcon />
                                                                            </IconButton>
                                                                        )}
                                                                        {payment.id === row.id ? (
                                                                            <TransactionRefund
                                                                                setShow={setShow}
                                                                                payment={row}
                                                                                show={show}
                                                                                setPayment={setPayment}
                                                                                setIsRefundHappen={setIsRefundHappen}
                                                                            />
                                                                        ) : null}
                                                                    </TableCell>
                                                                ))}
                                                            {statusFilter === PaymentsFilterTabs.All && isOmnipay && (
                                                                <TableCell
                                                                    className={styles.status}
                                                                    onClick={() => handleClick(row)}
                                                                >
                                                                    {row.total <= 0 ? (
                                                                        <TransactionStatusLabel status={'REFUNDED'} />
                                                                    ) : PaymentStatus[row.payment_status] ? (
                                                                        <TransactionStatusLabel
                                                                            status={PaymentStatus[row.payment_status]}
                                                                        />
                                                                    ) : row.payment_status ? (
                                                                        <TransactionStatusLabel
                                                                            status={row.payment_status}
                                                                        />
                                                                    ) : null}
                                                                </TableCell>
                                                            )}
                                                        </TableRow>
                                                        {mediaLessThan600px && (
                                                            <TableRow className={styles.mobileViewTransactionDetails}>
                                                                <TableCell
                                                                    colSpan={3}
                                                                    className={styles.nameAddressColumn}
                                                                    onClick={() => handleClick(row)}
                                                                >
                                                                    {row.refund ? (
                                                                        <div className={styles.currencySpan}>
                                                                            {formatRefundReasonAndAmountString(
                                                                                row.refund
                                                                            )}
                                                                            {transactionDetails && (
                                                                                <>
                                                                                    <br></br>
                                                                                    <span>{transactionDetails}</span>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div className={styles.currencySpan}>
                                                                            {transactionDetails ? (
                                                                                <span>{transactionDetails}</span>
                                                                            ) : (
                                                                                <span>&#8212;</span>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                            {pagePath !== 'dashboard' && (
                                                <TableRow>
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        {pagePath !== 'dashboard' && (
                                            <TableFooter>
                                                <TableRow>
                                                    {rows.length > rowsPerPage && (
                                                        <TablePagination
                                                            rowsPerPageOptions={[
                                                                20,
                                                                50,
                                                                100,
                                                                { label: 'All', value: -1 }
                                                            ]}
                                                            // colSpan={6}
                                                            count={rows.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            SelectProps={{
                                                                inputProps: { 'aria-label': 'rows per page' },
                                                                native: true
                                                            }}
                                                            onChangePage={handleChangePage}
                                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                                        />
                                                    )}
                                                </TableRow>
                                            </TableFooter>
                                        )}
                                    </Table>
                                    {pagePath !== 'dashboard' && (
                                        <TableFooterComponent
                                            path="payments"
                                            filter={props.statusFilter}
                                            total={successfullTransactions}
                                            refund={props.refundValue}
                                            resultsCount={rows.length}
                                        />
                                    )}
                                </TableContainer>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </Paper>
        </div>
    );
}

PaymentsListTableComponent.propTypes = {
    rows: PropTypes.array.isRequired,
    statusFilter: PropTypes.string.isRequired,
    setStatusFilter: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    setPayment: PropTypes.func,
    formatRefundReasonAndAmountString: PropTypes.func,
    noTabs: PropTypes.bool,
    showAcquirer: PropTypes.bool
};
