import React from 'react';
import styles from './upload-documents.module.scss';
import {
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Tooltip,
    CircularProgress,
    Typography,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Select,
    MenuItem
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import { SnackBarAndAlert } from 'components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DocumentStatus } from 'utils/enums/DocumentStatus';
import LoadingScreen from 'components/loading-screen/loading-screen.container';
import { useGlobalStateContext } from 'contexts';
import { Label, FormItem, Input } from 'components/onboarding/onboarding-ui-components';
import { translateText, FormatMessage } from 'utils/languageTranslater';
import { checkEmptyValue, validateCardNumber } from 'validators/onboarding/onboarding-check-value.validator';
import { MerchantCountries } from 'utils/enums/MerchantCountries';

const useStylesList = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper
    },
    secondaryAction: {
        width: '25%',
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

const useStylesListItemText = makeStyles(() => ({
    root: {
        maxWidth: '75%'
    }
}));

const useStylesCircular = makeStyles(() =>
    createStyles({
        previewChip: {
            width: '100% !important'
        },
        root: {
            minWidth: 160,
            maxWidth: 300
        },
        item: {
            display: 'block !important'
        }
    })
);

const UploadDocumentsComponent = (props) => {
    const { globalState } = useGlobalStateContext();

    const {
        onChange,
        uploadingOrUploadedFiles,
        onRemove,
        isShowDeleteWarning,
        setIsShowDeleteWarning,
        isDeletedSuccessfully,
        setIsDeletedSuccessfully,
        fileToBeRemoved,
        setFileToBeRemoved,
        handleListClick,
        addedFiles,
        dropZoneKey,
        nrMaxFilesExceedError,
        handleCloseAlertError,
        fileExistError,
        nonUniqueFile,
        isLoading,
        uploadingFailed,
        uploadPendingDocTypes,
        docTypeId,
        setDocTypeId,
        fileIsUploading,
        uploadFailureMessage,
        setIdNumber,
        idNumber,
        isFieldToBeShown,
        canUpload,
        cardNumber,
        setCardNumber,
        isValidIdNumber,
        source,
        isValidCardNumber,
        doValidation
    } = props;
    const classesCircular = useStylesCircular();
    const classesList = useStylesList();
    const classesListItemText = useStylesListItemText();
    const ausIdNum =
        (docTypeId == 4 || docTypeId == 3) && globalState?.merchant?.country === MerchantCountries.AUSTRALIA;
    function CircularProgressWithLabel(props) {
        return (
            <Box position="relative" display="inline-flex">
                <CircularProgress variant="static" {...props} />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                        props.value
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    return (
        <React.Fragment>
            <Grid item xs={12} className={styles.formItemGrid}>
                <FormItem>
                    {isFieldToBeShown && (
                        <div className={styles.idNumber}>
                            <Label text="ID Number" id="upload-document-id-number" transalterEnabled="true"></Label>
                            <Input
                                emptyField={!checkEmptyValue(idNumber) || !isValidIdNumber}
                                placeholder={FormatMessage({
                                    id: 'upload-document-id-number',
                                    text: 'ID Number'
                                })}
                                inputProps={{ maxLength: ausIdNum ? 11 : 15 }}
                                id="upload-document-id-number"
                                value={idNumber}
                                onChange={(e) => {
                                    if (source !== 'BANK_UPDATE') {
                                        if (ausIdNum) {
                                            let value = /^[a-zA-Z0-9]{0,11}$/.test(e.target.value);
                                            if (value) {
                                                setIdNumber(e.target.value.toUpperCase());
                                            }
                                        } else {
                                            let value = /^[a-zA-Z0-9]{0,15}$/.test(e.target.value);
                                            if (value) {
                                                setIdNumber(e.target.value.toUpperCase());
                                            }
                                        }
                                    }
                                }}
                            ></Input>
                            {!isValidIdNumber && (docTypeId == 4 || docTypeId == 3) && (
                                <p style={{ color: 'red' }}>{'Please input valid ID Number'}</p>
                            )}
                            {docTypeId == 3 && globalState?.merchant?.country === MerchantCountries.AUSTRALIA && (
                                <div className={styles.cardNumber}>
                                    <Label
                                        text="Card Number"
                                        id="upload-document-card-number"
                                        transalterEnabled="true"
                                    ></Label>
                                    <Input
                                        emptyField={isValidCardNumber}
                                        placeholder={FormatMessage({
                                            id: 'upload-document-card-number',
                                            text: 'Card Number'
                                        })}
                                        inputProps={{ maxLength: 11 }}
                                        id="upload-document-card-number"
                                        value={cardNumber}
                                        onChange={(e) => {
                                            if (validateCardNumber(e.target.value) && source !== 'BANK_UPDATE') {
                                                doValidation(e.target.value);
                                                setCardNumber(e.target.value.toUpperCase());
                                            }
                                        }}
                                    ></Input>
                                    {isValidCardNumber && (
                                        <p style={{ color: 'red' }}>{'Please input valid card number'}</p>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    <Label text="Document type" id="document-type-label" transalterEnabled={true}></Label>
                    <Select
                        className={styles.select}
                        id="document-type"
                        value={docTypeId}
                        onChange={(e) => {
                            if (docTypeId != e.target.value && source !== 'BANK_UPDATE') {
                                setIdNumber('');
                                setCardNumber('');
                            }
                            setDocTypeId(e.target.value);
                        }}
                    >
                        {uploadPendingDocTypes.map((docType) => {
                            return (
                                <MenuItem key={docType.id} value={docType.id}>
                                    {translateText({
                                        id: docType.id === -1 ? 'None' : docType.name,
                                        text: docType.name
                                    })}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormItem>
            </Grid>
            <Grid item xs={12} className={styles.formItemGrid}>
                <div
                    className={
                        docTypeId < 0 ||
                        fileIsUploading ||
                        (docTypeId == 3 &&
                            !canUpload &&
                            (globalState?.merchant?.country === MerchantCountries.AUSTRALIA ||
                                globalState?.merchant?.country === MerchantCountries.UNITED_STATES)) ||
                        (docTypeId == 4 && !canUpload && globalState?.merchant?.country === MerchantCountries.AUSTRALIA)
                            ? styles.zoneDisabled
                            : styles.zoneEnable
                    }
                >
                    {
                        <DropzoneArea
                            id="dropzone"
                            key={dropZoneKey}
                            dropzoneClass={styles.dropzoneClass}
                            showPreviewsInDropzone={false}
                            showAlerts={false}
                            useChipsForPreview={false}
                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                            previewChipProps={{ classes: { root: classesCircular.root } }}
                            previewText="Selected files"
                            maxFileSize={globalState?.merchant?.isAdyenMerchant ? 12000000 : 30000000} //30MB
                            acceptedFiles={['image/jpeg', 'image/png', 'application/pdf']}
                            filesLimit={1}
                            onChange={onChange}
                            dropzoneText={translateText({
                                id: 'dropzoneText',
                                text: 'Drag and drop a file here or click'
                            })}
                        />
                    }
                </div>
            </Grid>
            {nrMaxFilesExceedError && (
                <SnackBarAndAlert open={nrMaxFilesExceedError} onClose={handleCloseAlertError} type="error">
                    Number of files to add {addedFiles.length} + Added files {uploadingOrUploadedFiles.length} exceed
                    limit of 20.
                </SnackBarAndAlert>
            )}
            {fileExistError && (
                <SnackBarAndAlert open={fileExistError} onClose={handleCloseAlertError} type="error">
                    File with name {nonUniqueFile.name} already exist.
                </SnackBarAndAlert>
            )}
            {uploadingFailed && (
                <SnackBarAndAlert open={uploadingFailed} onClose={handleCloseAlertError} type="error">
                    {uploadFailureMessage}
                </SnackBarAndAlert>
            )}

            {isDeletedSuccessfully && (
                <SnackBarAndAlert
                    open={isDeletedSuccessfully}
                    onClose={() => setIsDeletedSuccessfully(false)}
                    type="success"
                >
                    File was deleted successfully.
                </SnackBarAndAlert>
            )}
            {uploadingOrUploadedFiles && uploadingOrUploadedFiles.length > 0 && (
                <Grid item xs={12} className={styles.formItemGrid}>
                    <List className={classesList.root} id="listItem">
                        {uploadingOrUploadedFiles.map((uploadingFile) => {
                            return (
                                <ListItem
                                    button
                                    key={uploadingFile.id}
                                    onClick={(event) => handleListClick(event, uploadingFile)}
                                >
                                    <Tooltip title={uploadingFile.filename} placement="top">
                                        <ListItemText
                                            className={classesListItemText.root}
                                            primary={<Typography noWrap>{uploadingFile.filename}</Typography>}
                                            secondary={FormatMessage({
                                                id: uploadingFile.documentTypeName || 'Unknown',
                                                text: uploadingFile.documentTypeName || 'Unknown'
                                            })}
                                        ></ListItemText>
                                    </Tooltip>

                                    <ListItemSecondaryAction className={classesList.secondaryAction}>
                                        {uploadingFile.status !== DocumentStatus.SUCCESS &&
                                            uploadingFile.isUploading && (
                                                <CircularProgressWithLabel value={uploadingFile.percentageProgress} />
                                            )}

                                        {uploadingFile.status === DocumentStatus.SUCCESS && (
                                            <IconButton edge="end" aria-label="Uploaded">
                                                <DoneIcon />
                                            </IconButton>
                                        )}
                                        {uploadingFile.status === DocumentStatus.ERROR && (
                                            <IconButton edge="end" aria-label="Error">
                                                <ErrorIcon />
                                            </IconButton>
                                        )}
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() =>
                                                setFileToBeRemoved(uploadingFile) &
                                                setIsShowDeleteWarning((state) => !state)
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                </Grid>
            )}

            {isShowDeleteWarning && (
                <Dialog
                    open={isShowDeleteWarning}
                    onClose={() => setIsShowDeleteWarning(false)}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Delete file
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete file {fileToBeRemoved.filename}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={styles.actionsContainer}>
                        {isLoading && <CircularProgress className={styles.loading} color="inherit" />}
                        <Button
                            id="cancelButtonDeleteMerchant"
                            onClick={() => setIsShowDeleteWarning(false)}
                            color="primary"
                            variant="contained"
                        >
                            Cancel
                        </Button>
                        <Button id="createButtonDeleteMerchant" onClick={onRemove} color="primary" variant="contained">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <LoadingScreen open={isLoading}></LoadingScreen>
        </React.Fragment>
    );
};

UploadDocumentsComponent.propTypes = {
    onSubmit: PropTypes.func,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    canUpload: PropTypes.bool,
    handleSave: PropTypes.func,
    onChange: PropTypes.func,
    files: PropTypes.array,
    uploadingOrUploadedFiles: PropTypes.array,
    onRemove: PropTypes.func,
    isShowDeleteWarning: PropTypes.bool,
    setIsShowDeleteWarning: PropTypes.func,
    isDeletedSuccessfully: PropTypes.bool,
    setIsDeletedSuccessfully: PropTypes.func,
    fileToBeRemoved: PropTypes.any,
    setFileToBeRemoved: PropTypes.func,
    handleListClick: PropTypes.func,
    addedFiles: PropTypes.array,
    dropZoneKey: PropTypes.number,
    nrMaxFilesExceedError: PropTypes.bool,
    handleCloseAlertError: PropTypes.func,
    fileExistError: PropTypes.bool,
    nonUniqueFile: PropTypes.object,
    uploadingFailed: PropTypes.bool,
    uploadBtnName: PropTypes.string,
    removeFileFromDropzone: PropTypes.func
};

export default UploadDocumentsComponent;
