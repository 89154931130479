import React from 'react';
import { Grid, Button, Dialog, DialogActions } from '@material-ui/core';
import { LoadingScreen } from 'components';
import styles from './adyen-accept-TOS.module.scss';

const ModalAcceptTOS = (props) => {
    const { openTOSModal, handleCancleClick, viewerRef, checked, setChecked, loading, acceptTermsAndConditon } = props;

    return (
        <React.Fragment>
            {loading ? (
                <LoadingScreen open={loading} />
            ) : (
                <Dialog
                    open={openTOSModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={styles.modalContainer}
                >
                    <Grid
                        container
                        xs={12}
                        justify="space-between"
                        alignItems="center"
                        spacing={2}
                        className={styles.containerStyles}
                    >
                        <div id="document-viewer" ref={viewerRef} className={styles.viewerContainer}></div>

                        <Grid item xs={12}>
                            <div>
                                <label>
                                    <input
                                        className={styles.acquirerInput}
                                        type="checkbox"
                                        onChange={(e) => setChecked(!checked)}
                                        checked={checked}
                                    />
                                    <span>Terms and condition</span>
                                </label>
                            </div>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => handleCancleClick(false)} color="primary">
                            Cancel
                        </Button>
                        <Button
                            className={styles.yesBtn}
                            variant="contained"
                            onClick={() => acceptTermsAndConditon()}
                            color="primary"
                            disabled={!checked}
                        >
                            Accept t&c
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    );
};

ModalAcceptTOS.propTypes = {};
export default ModalAcceptTOS;
