import React from 'react';
import PropTypes from 'prop-types';
import { Typography, MenuItem, Button, Box, useMediaQuery, Select, Tooltip, FormControl } from '@material-ui/core';
import ModalAcquirerFilter from 'components/modal-acquirer-filter/modal-acquirer-filter.container';
import { MerchantCountries } from 'utils/enums/MerchantCountries';

import { makeStyles } from '@material-ui/core/styles';
import PaymentsFilter from '../payments-filter/payments-filter.container';
import styles from './page-header.module.scss';
import { useGlobalStateContext } from 'contexts';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
import { UserType } from 'utils/enums/UserType';
import { ModalExportReport, SnackBarAndAlert } from 'components';
import ModalExportScheduleReport from 'components/modal-export-report-schedule/modal-export-report-schedule.container';
import { ResellerType } from 'utils/enums/ResellerType';
import { AcquirersToAcquirerName } from 'utils/enums/Acquirers';
import { cleverTapClick } from 'utils/helper';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CallMadeIcon from '@material-ui/icons/CallMade';
import ModalSendReport from 'components/modal-send-report/modal-send-report.container';
import { MerchantStatus } from 'utils/enums/MerchantStatus';

const useStyles = makeStyles((theme) => ({
    select: {
        position: 'relative',
        zIndex: 1,
        top: '7px',
        '&:before': {
            borderBottom: 'none'
        },
        '&:after': {
            borderBottom: 'none',
            backgroundColor: '#fff'
        },
        '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
            borderBottom: 'none'
        },
        '&:focus': {
            borderBottom: 'none',
            backgroundColor: '#fff !important'
        }
    },
    input: {
        color: '#8783b5',
        fontSize: '12px !important',
        fontWeight: '500',
        backgroundColor: '#fff',
        '&:focus': {
            borderBottom: 'none',
            backgroundColor: '#fff !important'
        }
    },
    selectInput: {
        color: 'transparent !important',
        fontSize: '12px !important',
        fontWeight: '500',
        '&:focus': {
            borderBottom: 'none'
        }
    },
    exportButton: {
        margin: theme.spacing(1),
        textTransform: 'none'
    }
}));
const PageHeaderComponent = (props) => {
    const { globalState } = useGlobalStateContext();
    const classes = useStyles();
    const {
        via,
        date,
        setVia,
        path,
        showFilterButton,
        setChannelFilter,
        channelFilter,
        setShowReportModel,
        showReportModel,
        onCancelExport,
        onConfirmExport,
        setOffrange,
        locationInApp,
        onAcquirerChange,
        acquirer,
        setSelectedProvider,
        selectedProvider,
        payoutAcquireFilter,
        startDate,
        endDate,
        callClevertap,
        daysRange,
        setDaysRange,
        handleMerchantModal,
        handleAcquirerChange,
        acquirerName,
        handleClearFilter,
        acquirerFilter,
        setAcquirerFilter,
        onCancelScheduleExport,
        onConfirmScheduleExport,
        handleBtnChange,
        showExportScheduleButton,
        setShowReportScheduleModel,
        showReportScheduleModel,
        exportSnackBar,
        setExportSnackBar,
        btn,
        sendReportModal,
        setSendReportModal,
        cutoffdateForExportBreached
    } = props;
    let showExportButton = globalState.merchant?.isStripe
        ? (path === 'payments' ||
              path === 'payouts' ||
              path === 'gfo-payments' ||
              path === 'pdq-transactions' ||
              path === 'merchant-charges' ||
              path === 'chargebacks' ||
              path === 'internal-transfers') &&
          locationInApp !== 'merchant-details'
        : (path === 'pdq-transactions' ||
              path === 'merchant-charges' ||
              path === 'payouts' ||
              path === 'gfo-payments' ||
              path === 'chargebacks' ||
              path === 'internal-transfers' ||
              path === 'payments') &&
          locationInApp !== 'merchant-details';
    let showMerchantChargesButton = path === 'merchant-charges';
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isSuperAdmin = globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isDatmanReseller = globalState.reseller?.name === ResellerType.DATMAN;
    const showWeekly = props.title !== 'Merchant Charges' && props.title !== 'list refunds';
    const showYearly = props.title === 'Merchant Charges';
    const showDaily = props.title !== 'Merchant Charges';
    const mediaLessThan640px = useMediaQuery('(max-width:640px)');
    const showScheduleExportDropdown = isDatmanReseller;
    const showScheduleExportButton =
        isDatmanReseller && (globalState.merchant?.country === MerchantCountries.NEW_ZEALAND || isAdmin);
    const isMerchantAccountClosed = globalState.merchant?.status === MerchantStatus.CLOSED;
    return (
        <div
            className={
                showMerchantChargesButton && mediaLessThan640px ? styles.merchantChargesContainer : styles.containerDiv
            }
        >
            <div className={styles.flexDiv}>
                <Typography color={'primary'}>
                    <p className={styles.pageTitle}>{props.title}</p>
                </Typography>
            </div>
            <div
                className={
                    payoutAcquireFilter && isAdmin ? styles.flexDiv + ' ' + styles.payoutFlexDiv : styles.flexDiv
                }
            >
                {showFilterButton && (
                    <PaymentsFilter setChannelFilter={setChannelFilter} channelFilter={channelFilter} />
                )}

                <div className={styles.flexDiv}>
                    {onAcquirerChange && isAdmin && isDatmanReseller && (
                        <>
                            {acquirer?.length !== 0 && acquirer?.length !== acquirerName?.length && (
                                <div className={styles.rangeSelectTwo}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        className={styles.filterBtn}
                                        onClick={handleClearFilter}
                                    >
                                        clear filter
                                    </Button>
                                </div>
                            )}

                            <div className={styles.rangeSelectOne}>
                                <Tooltip title="Select Acquirer">
                                    {acquirer?.length !== 0 ? (
                                        <FilterAltIcon
                                            className={styles.filterIcon}
                                            onClick={() => setAcquirerFilter(true)}
                                            style={{ color: '#005896' }}
                                        />
                                    ) : (
                                        <FilterAltOutlinedIcon
                                            className={styles.filterIcon}
                                            onClick={() => setAcquirerFilter(true)}
                                            style={{ color: '#005896' }}
                                        />
                                    )}
                                </Tooltip>
                            </div>
                        </>
                    )}
                    <ModalAcquirerFilter
                        acquirerFilter={acquirerFilter}
                        setAcquirerFilter={setAcquirerFilter}
                        acquirerName={acquirerName}
                        acquirer={acquirer}
                        handleAcquirerChange={handleAcquirerChange}
                    />
                    {payoutAcquireFilter && isAdmin && (
                        <div className={styles.rangeSelect}>
                            <Select
                                className={classes.select + ' ' + styles.select}
                                value={selectedProvider}
                                onChange={(e) => {
                                    setSelectedProvider(e.target.value);
                                    sessionStorage.setItem('selectedProvider', e.target.value);
                                }}
                                inputProps={{
                                    className: classes.input
                                }}
                            >
                                {Object.keys(AcquirersToAcquirerName).map((acquirer) => (
                                    <MenuItem value={AcquirersToAcquirerName[acquirer]}>
                                        {AcquirersToAcquirerName[acquirer]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )}
                    {via && path !== 'list-chargebacks' && path !== 'refund-list' && (
                        <div className={styles.rangeSelect}>
                            <Select
                                className={classes.select + ' ' + styles.select}
                                value={via}
                                onChange={(e) => {
                                    setVia(e.target.value);
                                    setOffrange(false);
                                    callClevertap(e.target.value);
                                    if (setDaysRange) setDaysRange({});
                                }}
                                inputProps={{
                                    className: classes.input
                                }}
                            >
                                {showDaily && <MenuItem value={'day'}>Daily</MenuItem>}
                                {showWeekly && <MenuItem value={'week'}>Weekly</MenuItem>}
                                <MenuItem value={'month'}>Monthly</MenuItem>
                                {showYearly && <MenuItem value={'year'}>Yearly</MenuItem>}
                            </Select>
                        </div>
                    )}
                </div>
                {path === 'internal-transfers' && (
                    <div className={styles.internalTransferPending}>
                        <Typography>Total pending:</Typography>
                        <Typography>{getCurrencySymbol(globalState.merchant?.country)}</Typography>
                        <Typography>{props.totalPendingInternalTransfersAmount.toFixed(2)}</Typography>
                    </div>
                )}
                {showMerchantChargesButton && isSuperAdmin && !mediaLessThan640px && (
                    <Box mx={2} sm={12}>
                        <Button
                            variant="contained"
                            onClick={handleMerchantModal}
                            color="primary"
                            className={styles.addSpecialRentBtn}
                            disabled={isMerchantAccountClosed}
                        >
                            + Merchant Charges
                        </Button>
                    </Box>
                )}

                {showScheduleExportDropdown && path === 'payments' ? (
                    <Box mx={2}>
                        <FormControl variant="outlined">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={btn}
                                onChange={handleBtnChange}
                                className={styles.exportSelect}
                            >
                                <MenuItem value={'export-view'} disabled={true}>
                                    <CallMadeIcon></CallMadeIcon>
                                    Export View
                                </MenuItem>
                                <MenuItem
                                    value={'show-report'}
                                    onClick={() => {
                                        const data = {
                                            date_start: startDate,
                                            date_end: endDate,
                                            time_stamp: new Date(),
                                            type_of_payment: path
                                        };
                                        cleverTapClick('Report_export_click', globalState, data);

                                        setShowReportModel(true);
                                    }}
                                    disabled={cutoffdateForExportBreached(startDate, endDate)}
                                >
                                    Export Now
                                </MenuItem>
                                <MenuItem
                                    value={'reprort-via-email'}
                                    onClick={() => {
                                        setSendReportModal(true);
                                    }}
                                    disabled={isMerchantAccountClosed}
                                >
                                    Send Report
                                </MenuItem>
                                {showScheduleExportButton && (
                                    <MenuItem
                                        value={'show-report-schedule'}
                                        onClick={() => {
                                            setShowReportScheduleModel(true);
                                        }}
                                        disabled={isMerchantAccountClosed}
                                    >
                                        Schedule Export
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                ) : showExportButton ? (
                    <Box mx={2} className={styles.exportButton}>
                        <Button
                            id="transactionsExportBtn"
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={() => {
                                const data = {
                                    date_start: startDate,
                                    date_end: endDate,
                                    time_stamp: new Date(),
                                    type_of_payment: path
                                };
                                cleverTapClick('Report_export_click', globalState, data);

                                setShowReportModel(true);
                            }}
                            disabled={cutoffdateForExportBreached(startDate, endDate)}
                        >
                            Export
                        </Button>
                    </Box>
                ) : null}
            </div>

            {showMerchantChargesButton && isSuperAdmin && mediaLessThan640px && (
                <Box mx={2} sm={12} className={styles.mobileMC}>
                    <Button
                        variant="contained"
                        onClick={handleMerchantModal}
                        color="primary"
                        className={styles.addSpecialRentBtn}
                    >
                        + Merchant Charge
                    </Button>
                </Box>
            )}
            {showExportScheduleButton && (
                <Box mx={2}>
                    <Button
                        id="transactionsExportBtn"
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            setShowReportScheduleModel(true);
                        }}
                    >
                        <CallMadeIcon />
                    </Button>
                </Box>
            )}

            {showReportModel && (
                <ModalExportReport
                    path={path}
                    reportType={path}
                    timeFrameDetails={{ via: via || 'month', date: date }}
                    cancelExport={onCancelExport}
                    confirmExport={onConfirmExport}
                    setShowReportModel={setShowReportModel}
                    selectedAcquirer={acquirer}
                    showReportModel={showReportModel}
                    daysRange={daysRange}
                    setDaysRange={setDaysRange}
                    isAdmin={isAdmin}
                    setExportSnackBar={setExportSnackBar}
                    exportSnackBar={exportSnackBar}
                />
            )}

            {showReportScheduleModel ? (
                <ModalExportScheduleReport
                    path={path}
                    reportType={path}
                    timeFrameDetails={{ via: via || 'month', date: date }}
                    cancelExport={onCancelScheduleExport}
                    confirmExport={onConfirmScheduleExport}
                    setShowReportScheduleModel={setShowReportScheduleModel}
                    selectedAcquirer={acquirer}
                    showReportScheduleModel={showReportScheduleModel}
                    daysRange={daysRange}
                    setDaysRange={setDaysRange}
                    isAdmin={isAdmin}
                    scheduleExportSnackBar={exportSnackBar}
                    setScheduleExportSnackBar={setExportSnackBar}
                />
            ) : null}
            {sendReportModal && (
                <ModalSendReport
                    sendReportModal={sendReportModal}
                    setSendReportModal={setSendReportModal}
                    setExportSnackBar={setExportSnackBar}
                    exportSnackBar={exportSnackBar}
                />
            )}
            {exportSnackBar.message && (
                <SnackBarAndAlert
                    open={exportSnackBar.open}
                    type={exportSnackBar.type}
                    onClose={() => setExportSnackBar({ open: false, type: '', message: '' })}
                >
                    {exportSnackBar.message}
                </SnackBarAndAlert>
            )}
        </div>
    );
};
export default PageHeaderComponent;
PageHeaderComponent.propTypes = {
    via: PropTypes.string,
    date: PropTypes.string,
    setVia: PropTypes.func,
    onChangeDateRange: PropTypes.func,
    path: PropTypes.string.isRequired,
    showFilterButton: PropTypes.bool,
    channelFilter: PropTypes.string,
    setChannelFilter: PropTypes.func,
    totalPendingInternalTransfersAmount: PropTypes.number,
    title: PropTypes.string,
    setOffrange: PropTypes.func,
    onAcquirerChange: PropTypes.func
};
