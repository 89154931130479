import React, { useState, useEffect } from 'react';
import ModalSendReportComp from './modal-send-report.component';
import PaymentsService from 'services/payments.service';
import moment from 'moment';
import { useGlobalStateContext } from 'contexts';

const ModalSendReport = (props) => {
    const {
        reportType,

        sendReportModal,
        setSendReportModal,
        scheduleExportSnackBar,
        setScheduleExportSnackBar,
        setExportSnackBar
    } = props;
    const [loading, setLoading] = useState(false);
    const [showCharges, setShowCharges] = useState(true);
    const [timeFrame, setTimeFrame] = useState('year');
    const [fromDate, setFromDate] = useState(moment().startOf('year').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [disable, setDisable] = useState(false);

    const { globalState } = useGlobalStateContext();
    const handleClose = () => {
        setSendReportModal(false);
    };

    const handleTimeFrameChange = (event) => {
        setTimeFrame(event.target.value);
    };

    useEffect(() => {
        if (timeFrame === 'custom') {
            const isCustomDateValid = fromDate && toDate && fromDate <= toDate;
            setDisable(!isCustomDateValid);
        }
    }, [fromDate, toDate]);

    useEffect(() => {
        const isYear = timeFrame === 'year';
        setFromDate(isYear ? moment().startOf('year').format('YYYY-MM-DD') : null);
        setToDate(isYear ? moment().format('YYYY-MM-DD') : null);
        setDisable(timeFrame !== 'year' && timeFrame !== 'custom');
    }, [timeFrame]);

    const onSend = async () => {
        let paymentFrom;
        let paymentTo;
        let transactionsRequest = {
            merchant_id: globalState?.merchant?.id.toString()
        };

        if (timeFrame === 'custom' || timeFrame === 'year') {
            transactionsRequest = {
                ...transactionsRequest,
                from_date: moment(fromDate).format('YYYY-MM-DD'),
                to_date: moment(toDate).format('YYYY-MM-DD')
            };
        }
        setLoading(true);
        const response = await PaymentsService.exportInCSV(globalState?.merchant?.id, transactionsRequest);
        if (response.isSuccesfully) {
            setExportSnackBar({ open: true, type: 'success', message: response.data?.message });
        } else {
            setExportSnackBar({ open: true, type: 'error', message: response.data?.message });
        }

        setLoading(false);
        handleClose();
    };

    return (
        <ModalSendReportComp
            {...props}
            loading={loading}
            reportType={reportType}
            timeFrame={timeFrame}
            setTimeFrame={setTimeFrame}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            handleClose={handleClose}
            disable={disable}
            setDisable={setDisable}
            onSend={onSend}
            scheduleExportSnackBar={scheduleExportSnackBar}
            setScheduleExportSnackBar={setScheduleExportSnackBar}
            handleTimeFrameChange={handleTimeFrameChange}
            showCharges={showCharges}
            setShowCharges={setShowCharges}
            sendReportModal={sendReportModal}
        />
    );
    // return <ModalSendReportComp />;
};

export default ModalSendReport;
