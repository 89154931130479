import React, { useEffect, useState } from 'react';
import { DocumentsService, NonResellerMerchantService, AuthService, AdminService } from 'services';
import UploadDocumentsComponent from './upload-documents.component';
import { DocumentStatus } from 'utils/enums/DocumentStatus';
import PropTypes from 'prop-types';
import { DocumentFlag } from 'utils/enums/DocumentFlag';
import { DocumentTypes } from 'utils/enums/DocumentType';
import { useGlobalStateContext } from 'contexts';
import { getFormattedDocumentsStatsForMerchant, isTokenGoingToExpire } from 'utils/helper';
import { UserType } from 'utils/enums/UserType';
import { ErrorCodes } from 'utils/enums/ErrorCode';
import { formTypes } from 'utils/enums/FormTypes';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { BusinessTypeEnumId } from 'utils/enums/BusinessType';
import { DocumentStatusToName } from 'utils/enums/DocumentReviewStatus';
import { useCallback } from 'react';
import { debounce } from '@material-ui/core';
function UploadDocuments(props) {
    const { setRoute, globalState } = useGlobalStateContext();
    const {
        entity,
        entityId,
        flag,
        setValidForm,
        setFormattedDocumentsStats,
        setUploadSuccess,
        businessTypeId,
        setIsFinishButtonDisabled,
        source,
        bankUpload,
        setBankUpload,
        setBucketDocuments,
        onlyBucketUpload,
        csrfToken,
        formType,
        setCSRFToken,
        cardNumber,
        setCardNumber,
        setIdNumber,
        idNumber
    } = props;
    const [addedFiles, setAddedFiles] = useState([]);
    const [dropZoneKey, setDropZoneKey] = useState(1);
    const [uploadedDocumentId, setUploadedDocumentId] = useState(null);
    const [filename, setFilename] = useState(null);
    const [filesize, setFilesize] = useState(null);
    const [fileStatus, setFileStatus] = useState(null);
    const [fileStatusDocumentId, setFileStatusDocumentId] = useState(null);
    const [progress, setProgress] = useState(0);
    const [uploadingOrUploadedFiles, setUploadingOrUploadedFiles] = useState([]);
    const [isShowDeleteWarning, setIsShowDeleteWarning] = useState(false);
    const [isDeletedSuccessfully, setIsDeletedSuccessfully] = useState(false);
    const [fileToBeRemoved, setFileToBeRemoved] = useState('');
    const [nrMaxFilesExceedError, setNrMaxFilesExceedError] = useState(false);
    const [fileExistError, setFileExistError] = useState(false);
    const [nonUniqueFile, setNonUniqueFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadingFailed, setUploadingFailed] = useState(false);
    const [uploadFailureMessage, setUploadFailureMessage] = useState('');
    const [uploadPendingDocTypes, setUploadPendingDocTypes] = useState([]);
    const [docTypeId, setDocTypeId] = useState(-1);
    const [fileIsUploading, setFileIsUploading] = useState(false);
    const [mandatoryFilesUploaded, setMandatoryFilesUploaded] = useState(false);
    const [isValidIdNumber, setIsValidIdNumber] = useState(false);
    const [isValidCardNumber, setIsValidCardNumber] = useState(false);
    const isFieldToBeShown =
        (globalState?.merchant?.country === MerchantCountries.AUSTRALIA && (docTypeId == 3 || docTypeId == 4)) ||
        (globalState?.merchant?.country === MerchantCountries.UNITED_STATES && docTypeId == 3);

    const canUpload =
        (globalState?.merchant?.country === MerchantCountries.AUSTRALIA &&
            idNumber?.length >= 6 &&
            docTypeId == 3 &&
            !isValidCardNumber) ||
        (docTypeId == 4 && idNumber?.length >= 6) ||
        (globalState?.merchant?.country === MerchantCountries.UNITED_STATES && idNumber?.length > 6 && docTypeId == 3);

    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;

    const generateCSRFToken = async () => {
        setIsLoading(true);
        const response = await AuthService.generateCSRFToken(
            formTypes.ONBOARDING_UPLOAD_DOCUMENTS,
            globalState.merchant?.id
        );
        if (response.isSuccesfully) {
            setCSRFToken(response.data.csrfToken);
        }
        setIsLoading(false);
        return response?.data?.csrfToken;
    };

    useEffect(() => {
        const fetchData = async () => {
            if (flag === DocumentFlag.ONBOARDING || flag === DocumentFlag.AGENT_UPLOAD) {
                const response = await DocumentsService.listObjects(entity, entityId, flag);
                if (response.isSuccesfully) {
                    if (response.data && response.data.length > 0) {
                        const dbFiles = response.data.map((file) => {
                            file.status = DocumentStatus.SUCCESS;
                            file.documentTypeName = DocumentTypes.find(
                                (type) => type.id.toString() === file.documentTypeId?.toString()
                            )?.name;
                            return file;
                        });

                        refreshUploadDocumentsPageState(dbFiles);
                    } else {
                        refreshUploadDocumentsPageState([]);
                    }
                }
            } else {
                await refreshUploadDocumentsPageState([]);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [entityId, source, businessTypeId]);

    useEffect(() => {
        if (onlyBucketUpload && setBucketDocuments) {
            setBucketDocuments(uploadingOrUploadedFiles);
        }
        // eslint-disable-next-line
    }, [uploadingOrUploadedFiles]);
    useEffect(() => {
        if (uploadedDocumentId && progress > 0) {
            const index = uploadingOrUploadedFiles.findIndex((uplFile) => {
                return uplFile.id === uploadedDocumentId;
            });

            if (index !== -1) {
                uploadingOrUploadedFiles[index].percentageProgress = progress;
            } else {
                const newObj = {
                    id: uploadedDocumentId,
                    filename: filename,
                    filesize: filesize,
                    percentageProgress: progress,
                    isUploading: true,
                    documentTypeName: DocumentTypes.find((type) => type.id.toString() === docTypeId.toString()).name,
                    documentTypeId: parseInt(docTypeId),
                    entity,
                    entityId,
                    flag
                };
                setUploadingOrUploadedFiles((oldArray) => [...oldArray, newObj]);
            }
        }
        // eslint-disable-next-line
    }, [uploadedDocumentId, progress]);

    useEffect(() => {
        if (fileStatus && fileStatusDocumentId) {
            const index = uploadingOrUploadedFiles.findIndex((uplFile) => {
                return uplFile.id === fileStatusDocumentId;
            });

            setFileToBeRemoved('');

            uploadingOrUploadedFiles[index].status = fileStatus;
            if (fileStatus === DocumentStatus.SUCCESS) {
                refreshUploadDocumentsPageState(
                    uploadingOrUploadedFiles.filter((uf) => uf.status === DocumentStatus.SUCCESS)
                );
            }

            setUploadingOrUploadedFiles((oldArray) => [...oldArray]);
        }

        // eslint-disable-next-line
    }, [fileStatusDocumentId]);

    const handleListClick = async (event, selectedFile) => {
        await DocumentsService.getObject(entity, entityId, selectedFile.id, selectedFile.filename);
    };

    const getLatestCsrfToken = async () => {
        let latestCSRFToken = csrfToken;
        if (formTypes.ONBOARDING_UPLOAD_DOCUMENTS === formType) {
            const isCSRFGoingToExpire = isTokenGoingToExpire(csrfToken);
            if (isCSRFGoingToExpire) {
                latestCSRFToken = await generateCSRFToken();
            }
        }
        return latestCSRFToken;
    };

    const onRemove = async () => {
        const latestCSRFToken = await getLatestCsrfToken();
        let response;
        if (!onlyBucketUpload) {
            response = await DocumentsService.removeObject(
                entity,
                entityId,
                fileToBeRemoved.id,
                fileToBeRemoved.filename,
                formType,
                latestCSRFToken
            );
        } else {
            response = await DocumentsService.removeBucketObject(
                entity,
                entityId,
                fileToBeRemoved.id,
                fileToBeRemoved.filename,
                formType,
                latestCSRFToken
            );
        }
        if (response.isSuccesfully) {
            const newList = uploadingOrUploadedFiles.filter((item) => item.id !== fileToBeRemoved.id);
            setFileToBeRemoved('');
            refreshUploadDocumentsPageState(
                newList.filter((uf) => uf.status === DocumentStatus.SUCCESS),
                fileToBeRemoved.documentTypeId
            );
            setIsDeletedSuccessfully(true);
            if (!isAdmin && !onlyBucketUpload) {
                AdminService.saveUserKycLogs(entityId, {
                    resellerId: globalState.reseller?.id,
                    notes: '',
                    documentId: fileToBeRemoved.id,
                    documentStatus: DocumentStatusToName.DELETED,
                    docTypeId: fileToBeRemoved.documentTypeId
                });
            }
        } else {
            const errorMessage =
                response.data.errorCode === ErrorCodes.CSRF_VALIDATION_FAILURE.errorCode
                    ? ErrorCodes.CSRF_VALIDATION_FAILURE.message
                    : ErrorCodes.DOCUMENT_UPLOAD_ERROR.message;
            setUploadFailureMessage(errorMessage);
            setUploadingFailed(true);
        }
        setIsShowDeleteWarning(false);
        setIsLoading(false);
    };

    const fileProgress = (documentId, filename, filesize, progress) => {
        setFileIsUploading(true);
        setIsLoading(false);
        const { loaded, total } = progress;
        const percentageProgress = Math.floor((loaded / total) * 100);

        setFilename(filename);
        setFilesize(filesize);
        setUploadedDocumentId(documentId);
        setProgress(percentageProgress);
    };

    const fileDone = (documentId, response) => {
        setFileStatus(response.status);
        setFileStatusDocumentId(documentId);
    };

    const onChange = async (newFiles) => {
        if (newFiles && newFiles.length > 0) {
            const latestCSRFToken = await getLatestCsrfToken();
            isFileUnique(newFiles);
            setAddedFiles(newFiles);
            setNrMaxFilesExceedError(false);
            setUploadingFailed(false);
            const uploadedLength = uploadingOrUploadedFiles.filter(
                (uplFile) => uplFile.status === DocumentStatus.SUCCESS
            ).length;
            if (uploadedLength + newFiles.length <= 20) {
                setIsLoading(true);
                let uploadedFiles;
                if (!onlyBucketUpload) {
                    uploadedFiles = await DocumentsService.upload(
                        entity,
                        entityId,
                        flag,
                        docTypeId,
                        newFiles,
                        fileProgress,
                        fileDone,
                        formType,
                        latestCSRFToken,
                        globalState.reseller?.id,
                        isAdmin,
                        docTypeId == 3 || docTypeId == 4 ? idNumber : '',
                        docTypeId == 3 ? cardNumber : ''
                    );
                } else {
                    uploadedFiles = await DocumentsService.uploadToBucket(
                        entity,
                        entityId,
                        newFiles,
                        fileProgress,
                        fileDone,
                        formType,
                        latestCSRFToken
                    );
                }
                if (uploadedFiles.isSuccesfully) {
                    setAddedFiles([]);
                    const newKey = dropZoneKey + 1;
                    setDropZoneKey(newKey);
                    sessionStorage.setItem('docTypeId', docTypeId);
                    setUploadSuccess(true);
                    setDocTypeId(-1);
                    setFileIsUploading(false);
                    setUploadedDocumentId(null);
                } else {
                    const errorMessage =
                        uploadedFiles.data.errorCode === ErrorCodes.CSRF_VALIDATION_FAILURE.errorCode
                            ? ErrorCodes.CSRF_VALIDATION_FAILURE.message
                            : ErrorCodes.DOCUMENT_UPLOAD_ERROR.message;
                    setUploadFailureMessage(errorMessage);
                    setUploadingFailed(true);
                }
                setIsLoading(false);
            } else {
                setNrMaxFilesExceedError(true);
            }
        }
    };

    useEffect(() => {
        const removeFileFromS3 = async (fileToRemove) => {
            const latestCSRFToken = await getLatestCsrfToken();
            await DocumentsService.removeBucketObject(
                entity,
                entityId,
                fileToRemove.id,
                fileToRemove.filename,
                formType,
                latestCSRFToken
            );
        };

        if (uploadingFailed && uploadedDocumentId) {
            const tempDocuments = uploadingOrUploadedFiles.filter((uf) => uf.id !== uploadedDocumentId);
            const fileToRemove = uploadingOrUploadedFiles.filter((uf) => uf.id === uploadedDocumentId)[0];
            console.log(fileToRemove);
            removeFileFromS3(fileToRemove);
            setUploadingOrUploadedFiles(tempDocuments);
        }
        //eslint-disable-next-line
    }, [uploadingFailed]);

    /**
     *
     * @param {[File]} newFiles
     */
    const isFileUnique = (newFiles) => {
        setFileExistError(false);
        const nonUnique = newFiles.find((val, index, arr) => {
            return arr.findIndex((existelem) => existelem.name === val.name) !== index;
        });

        if (nonUnique) {
            setFileExistError(true);
            setNonUniqueFile(nonUniqueFile);
        }
    };
    const handleCloseAlertError = () => {
        setNrMaxFilesExceedError(false);
        setUploadingFailed(false);
        setUploadFailureMessage('');
    };

    useEffect(() => {
        if (bankUpload !== undefined) {
            if (
                uploadingOrUploadedFiles?.length > 1 &&
                uploadPendingDocTypes.length === 1 &&
                uploadPendingDocTypes[0].id === -1
            ) {
                setBankUpload(true);
                setTimeout(() => {
                    setRoute('/home');
                }, 25000);
            } else {
                setBankUpload(false);
            }
        }

        // eslint-disable-next-line
    }, [uploadingOrUploadedFiles.length, uploadPendingDocTypes]);

    useEffect(() => {
        const uploadDoc = async () => {
            if (setIsFinishButtonDisabled) {
                let pendingDocTypesLength = 1;
                if (mandatoryFilesUploaded) {
                    pendingDocTypesLength = 4;
                }
                if (
                    uploadingOrUploadedFiles?.length > 0 &&
                    uploadPendingDocTypes.length <= pendingDocTypesLength &&
                    uploadPendingDocTypes[0].id === -1 &&
                    mandatoryFilesUploaded
                ) {
                    setIsFinishButtonDisabled(false);
                    await NonResellerMerchantService.updateMerchantData(entityId, {
                        accountStatus: 'ReviewPending'
                    });
                } else if (
                    uploadingOrUploadedFiles?.length > 0 &&
                    (businessTypeId === BusinessTypeEnumId.Partnership ||
                        businessTypeId === BusinessTypeEnumId.Other) &&
                    uploadPendingDocTypes[0].id === -1 &&
                    mandatoryFilesUploaded
                ) {
                    setIsFinishButtonDisabled(false);
                    await NonResellerMerchantService.updateMerchantData(entityId, {
                        accountStatus: 'ReviewPending'
                    });
                } else {
                    setIsFinishButtonDisabled(true);
                }
            }
        };
        uploadDoc();
        // eslint-disable-next-line
    }, [uploadingOrUploadedFiles.length, uploadPendingDocTypes, uploadPendingDocTypes.length]);

    useEffect(() => {
        const coutry = globalState.merchant?.country === MerchantCountries.AUSTRALIA;
        if (coutry) {
            let validNumber = /^[a-zA-Z0-9]{6,11}$/.test(idNumber);
            if (validNumber) {
                setIsValidIdNumber(true);
            } else {
                setIsValidIdNumber(false);
            }
        } else {
            let validNumber = /^[a-zA-Z0-9]{7,15}$/.test(idNumber);

            if (validNumber) {
                setIsValidIdNumber(true);
            } else {
                setIsValidIdNumber(false);
            }
        }
    }, [idNumber]);
    const cardValidation = (inputValue) => {
        let validNumber = /^[a-zA-Z0-9]{6,10}$/.test(inputValue);
        if (validNumber) {
            setIsValidCardNumber(false);
        } else if (inputValue.length === 0) {
            setIsValidCardNumber(false);
        } else {
            setIsValidCardNumber(true);
        }
    };

    // trigger validation function when user stop typing
    const doValidation = useCallback(debounce(cardValidation, 100), []);

    return (
        <UploadDocumentsComponent
            onChange={onChange}
            uploadingOrUploadedFiles={uploadingOrUploadedFiles}
            onRemove={onRemove}
            isShowDeleteWarning={isShowDeleteWarning}
            setIsShowDeleteWarning={setIsShowDeleteWarning}
            isDeletedSuccessfully={isDeletedSuccessfully}
            setIsDeletedSuccessfully={setIsDeletedSuccessfully}
            fileToBeRemoved={fileToBeRemoved}
            setFileToBeRemoved={setFileToBeRemoved}
            handleListClick={handleListClick}
            addedFiles={addedFiles}
            source={source}
            dropZoneKey={dropZoneKey}
            nrMaxFilesExceedError={nrMaxFilesExceedError}
            fileExistError={fileExistError}
            nonUniqueFile={nonUniqueFile}
            handleCloseAlertError={handleCloseAlertError}
            isLoading={isLoading}
            uploadingFailed={uploadingFailed}
            uploadPendingDocTypes={uploadPendingDocTypes}
            docTypeId={docTypeId}
            setDocTypeId={setDocTypeId}
            setIsFinishButtonDisabled={setIsFinishButtonDisabled}
            fileIsUploading={fileIsUploading}
            uploadFailureMessage={uploadFailureMessage}
            isFieldToBeShown={isFieldToBeShown}
            setIdNumber={setIdNumber}
            idNumber={idNumber}
            cardNumber={cardNumber}
            setCardNumber={setCardNumber}
            canUpload={canUpload}
            isValidIdNumber={isValidIdNumber}
            isValidCardNumber={isValidCardNumber}
            doValidation={doValidation}
        />
    );

    function refreshUploadDocumentsPageState(files, removedDocType) {
        const formattedDocumentsStats = getFormattedDocumentsStatsForMerchant({
            merchantBusinessTypeId: businessTypeId,
            merchantCountry: globalState.merchant?.country,
            source,
            merchantDocuments: files,
            resellerName: globalState.reseller?.name,
            isAdmin
        });

        let pendingDocTypes = [...formattedDocumentsStats.pendingDocumentTypes];
        pendingDocTypes.unshift(DocumentTypes[0]);
        setUploadPendingDocTypes(pendingDocTypes);
        setMandatoryFilesUploaded(formattedDocumentsStats.hasAllMandatoryDocsUploaded);
        setFormattedDocumentsStats(formattedDocumentsStats);
        setUploadingOrUploadedFiles(files);
        setValidForm(formattedDocumentsStats.pendingDocumentTypes.length === 0);
        setDocTypeId(-1);
        if (source !== 'BANK_UPDATE') {
            setIdNumber('');
            setCardNumber('');
        }
    }
}

UploadDocuments.propTypes = {
    entity: PropTypes.string,
    entityId: PropTypes.string,
    flag: PropTypes.bool,
    setValidForm: PropTypes.func,
    setUploadSuccess: PropTypes.func,
    uploadBtnName: PropTypes.string,
    bankUpload: PropTypes.bool,
    setBankUpload: PropTypes.func,
    setCount: PropTypes.func
};

export default UploadDocuments;
