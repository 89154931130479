import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControl,
    FormControlLabel,
    MenuItem,
    TextField,
    Select,
    Checkbox
} from '@material-ui/core';
import { LoadingScreen, DatePicker } from 'components';
import styles from './modal-send-report.module.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import moment from 'moment';
const ModalSendReportComp = (props) => {
    const {
        loading,
        timeFrame,
        handleTimeFrameChange,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        handleClose,
        disable,
        onSend,
        showCharges,
        setShowCharges,

        sendReportModal
    } = props;

    return (
        <React.Fragment>
            <Dialog
                open={sendReportModal}
                className={styles.dialog}
                maxWidth="lg"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        width: '80%',
                        maxWidth: '600px',
                        minWidth: '400px',
                        margin: 'auto',
                        padding: '20px'
                    }
                }}
            >
                <LoadingScreen open={loading} />

                <DialogTitle id="draggable-dialog-title" stickyHeader className={styles.dialogHeader}>
                    Export report transactions
                </DialogTitle>
                <DialogContent dividers>
                    <>
                        <div className="payments-none text-left">
                            <h3>Email Statements will be sent within an hour.</h3>
                            {/* <p className="text-muted">
                                        {' '}
                                        Select one of the options below and proceed to prepare your statement
                                    </p> */}
                            {/* hiding pdf and csv option as of now */}
                            {/* {false && (
                                <div className={styles.divStyle}>
                                    <b style={{ 'margin-top': '2.25%' }}>Format</b>
                                    <RadioGroup
                                        className="row justify-content-center"
                                        style={{ 'margin-left': '20%' }}
                                        row
                                        aria-label="setup-fee"
                                        value={isCsv}
                                        onChange={(e) => setIsCsv(e.target.value)}
                                    >
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio color="primary" />}
                                            label="CSV"
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio color="primary" />}
                                            label="PDF"
                                        />
                                    </RadioGroup>
                                </div>
                            )} */}
                        </div>
                    </>
                    <div className={styles.selectTimeFrame}>
                        <p className={styles.paraText}>Filter</p>
                        <FormControl variant="outlined">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={timeFrame}
                                onChange={handleTimeFrameChange}
                                className={styles.exportSelect}
                            >
                                <MenuItem value="year">Annual</MenuItem>
                                <MenuItem value="custom">Custom</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className={styles.datePicker}>
                        <DatePicker
                            label="From"
                            value={fromDate}
                            onChange={(newValue) => newValue && setFromDate(newValue['$d'])}
                            placeholder={moment(new Date()).format('D MMM YYYY')}
                            minDate={timeFrame === 'custom' ? null : undefined}
                            maxDate={timeFrame === 'custom' ? toDate ?? new Date() : new Date()}
                            disabled={timeFrame === 'year'}
                            styleClass={timeFrame === 'custom' ? 'blackClass' : 'defaultClass'}
                        />
                        <DatePicker
                            label="To"
                            value={toDate}
                            onChange={(newValue) => newValue && setToDate(newValue['$d'])}
                            placeholder={moment(new Date()).format('D MMM YYYY')}
                            minDate={timeFrame === 'custom' ? fromDate : fromDate}
                            maxDate={new Date()}
                            disabled={timeFrame === 'year'}
                            styleClass={timeFrame === 'custom' ? 'blackClass' : 'defaultClass'}
                        />
                    </div>

                    {/*<FormControlLabel*/}
                    {/*    control={<Checkbox color="primary" checked={showCharges} />}*/}
                    {/*    label="Include service fee"*/}
                    {/*/>*/}
                    {/* Commenting above code as per DN-11154*/}
                </DialogContent>
                <DialogActions className={styles.dialogActions}>
                    <Button variant="outlined" onClick={handleClose} color="primary" className={styles.dialogbtn}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            onSend();
                        }}
                        color="primary"
                        disabled={disable}
                        className={styles.dialogbtn}
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};
ModalSendReportComp.propTypes = {
    loading: PropTypes.bool,
    timeFrame: PropTypes.string,
    handleTimeFrameChange: PropTypes.func,
    setFromDate: PropTypes.func,
    setToDate: PropTypes.func,
    handleClose: PropTypes.func,
    disable: PropTypes.bool,
    onSend: PropTypes.func,
    showCharges: PropTypes.bool,
    setShowCharges: PropTypes.func,
    sendReportModal: PropTypes.bool
};
export default ModalSendReportComp;
