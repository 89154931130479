import React from 'react';
import styles from './date-picker.module.scss';
import { TextField } from '@material-ui/core';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import moment from 'moment';
const DatePickerComponent = (props) => {
    const { label, value, onChange, placeholder, maxDate, minDate, disabled, styleClass, error } = props;

    console.log(value, minDate, error);

    const outlineClass = error ? styles.errorClass : styleClass ? styles[styleClass] : styles.defaultClass;
    return (
        <div>
            <p className={styles.paraText}>{label}</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                    inputFormat="D MMM YYYY"
                    value={value}
                    onChange={onChange}
                    maxDate={maxDate}
                    minDate={minDate}
                    disabled={disabled}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder}
                            className={styles.dialogContentDatePicker}
                            variant="outlined"
                            size="small"
                            error={error}
                            InputProps={{
                                classes: {
                                    notchedOutline: outlineClass
                                }
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
        </div>
    );
};

export default DatePickerComponent;
