import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { ResellerType } from 'utils/enums/ResellerType';

export const DocumentTypesId = {
    BANK_STATEMENT: 1,
    BUSINESS_RATES_BILL: 2,
    DRIVING_LICENCE: 3,
    PASSPORT: 4,
    RENTAL_AGREEMENT: 5,
    RESIDENCY_PERMIT: 6,
    UTILITY_BILL: 7,
    VOID_CHEQUE: 8,
    ID_PROOF_FRONT: 9,
    ID_PROOF_BACK: 10,
    BUSINESS_REGISTRATION_CERTIFICATE: 11,
    STREET_VIEW: 12,
    FOOD_HYGIENE_CERTIFICATE: 13,
    PROOF_OF_ORGANIZATION_TAX_INFO: 14,
    VAT_DOCUMENT: 15
};

export const DocumentTypesToName = {
    1: 'Bank statement',
    2: 'Business rates bill',
    3: 'Driving licence',
    4: 'Passport',
    5: 'Rental agreement',
    6: 'Residency permit',
    7: 'Utility bill',
    8: 'Void cheque',
    9: 'Proof of ID frontside',
    10: 'Proof of ID backside',
    11: 'Business registration certificate',
    12: 'Street View',
    13: 'Food Hygiene Certificate',
    14: 'Proof of organization tax info',
    15: 'VAT document'
};

export const DocumentTypes = [
    { id: -1, name: '<None>' },
    { id: 1, name: 'Bank statement' },
    { id: 2, name: 'Business rates bill' },
    { id: 3, name: 'Driving licence' },
    { id: 4, name: 'Passport' },
    { id: 5, name: 'Rental agreement' },
    { id: 6, name: 'Residency permit' },
    { id: 7, name: 'Utility bill' },
    { id: 8, name: 'Void cheque' },
    { id: 9, name: 'Proof of ID frontside' },
    { id: 10, name: 'Proof of ID backside' },
    { id: 11, name: 'Business registration certificate' },
    { id: 12, name: 'Street View' },
    { id: 13, name: 'Food Hygiene Certificate' },
    { id: 14, name: 'Proof of organization tax info' },
    { id: 15, name: 'VAT document' }
];

export const DocumentsCategoriesCountryWise = {
    [MerchantCountries.UNITED_KINGDOM]: [
        {
            categoryName: 'Proof of Bank ( Bank statement or Void cheque )',
            isBankUpdateCategory: true,
            documents: [
                {
                    typeIds: [1],
                    neededInBusinessTypeIds: [1, 12]
                },
                {
                    typeIds: [8],
                    neededInBusinessTypeIds: [1, 12]
                }
            ],
            neededInBusinessTypeIds: [1, 12]
        },
        {
            categoryName: 'Passport or Driving licence or Residency permit',
            documents: [
                {
                    typeIds: [4],
                    neededInBusinessTypeIds: [1, 12]
                },
                {
                    typeIds: [3],
                    neededInBusinessTypeIds: [1, 12]
                },
                {
                    typeIds: [6],
                    neededInBusinessTypeIds: [1, 12]
                }
            ],
            neededInBusinessTypeIds: [1, 12]
        },
        {
            categoryName: 'Bank statement in the Business name or Void cheque',
            isBankUpdateCategory: true,
            documents: [
                {
                    typeIds: [1],
                    neededInBusinessTypeIds: [2]
                },
                {
                    typeIds: [8],
                    neededInBusinessTypeIds: [2]
                }
            ],
            neededInBusinessTypeIds: [2]
        },
        {
            categoryName: 'Utility bill or Rental agreement or Business rates bill',
            documents: [
                {
                    typeIds: [7],
                    neededInBusinessTypeIds: [2, 12]
                },
                {
                    typeIds: [5],
                    neededInBusinessTypeIds: [2, 12]
                },
                {
                    typeIds: [2],
                    neededInBusinessTypeIds: [2, 12]
                }
            ],
            neededInBusinessTypeIds: [2, 12]
        },
        {
            categoryName: 'Passport or driving Licence',
            documents: [
                {
                    typeIds: [4],
                    neededInBusinessTypeIds: [2]
                },
                {
                    typeIds: [3],
                    neededInBusinessTypeIds: [2]
                }
            ],
            neededInBusinessTypeIds: [2]
        }
    ],
    [MerchantCountries.MEXICO]: [
        {
            categoryName: 'Bank Statement under Business name',
            isBankUpdateCategory: true,
            documents: [
                {
                    typeIds: [1],
                    neededInBusinessTypeIds: [1]
                }
            ],
            neededInBusinessTypeIds: [1]
        },
        {
            categoryName: 'Proof of ID (Passport, Driving license, Residency Card)',
            documents: [
                {
                    typeIds: [9, 10],
                    neededInBusinessTypeIds: [1]
                }
            ],
            neededInBusinessTypeIds: [1]
        },
        {
            categoryName: 'Bank Statement under Business Name if available (or personal)',
            isBankUpdateCategory: true,
            documents: [
                {
                    typeIds: [1],
                    neededInBusinessTypeIds: [2]
                }
            ],
            neededInBusinessTypeIds: [2]
        },
        {
            categoryName: 'Proof of ID (Passport, Driving license, Residency Card)',
            documents: [
                {
                    typeIds: [9, 10],
                    neededInBusinessTypeIds: [2]
                }
            ],
            neededInBusinessTypeIds: [2]
        },
        {
            categoryName: 'Utility bill (gas, water , electric) or rental agreement or Business Rates bill',
            documents: [
                {
                    typeIds: [7],
                    neededInBusinessTypeIds: [2]
                },
                {
                    typeIds: [5],
                    neededInBusinessTypeIds: [2]
                },
                {
                    typeIds: [2],
                    neededInBusinessTypeIds: [2]
                }
            ],
            neededInBusinessTypeIds: [2]
        }
    ],
    [MerchantCountries.CANADA]: [
        {
            categoryName: 'Business registration certificate',
            documents: [
                {
                    typeIds: [11],
                    neededInBusinessTypeIds: [1]
                }
            ],
            neededInBusinessTypeIds: [1]
        },
        {
            categoryName: 'Bank statement under Business name',
            isBankUpdateCategory: true,
            documents: [
                {
                    typeIds: [1],
                    neededInBusinessTypeIds: [1, 2]
                }
            ],
            neededInBusinessTypeIds: [1, 2]
        },
        {
            categoryName: 'Proof of ID (Passport, Driving license, Residency card)',
            documents: [
                {
                    typeIds: [9, 10],
                    neededInBusinessTypeIds: [1, 2]
                }
            ],
            neededInBusinessTypeIds: [1, 2]
        }
    ],
    [MerchantCountries.UNITED_STATES]: [
        {
            categoryName: 'Proof of Bank ( Bank statement or Void cheque )',
            isBankUpdateCategory: true,
            documents: [
                {
                    typeIds: [1],
                    neededInBusinessTypeIds: [1, 10, 11, 12]
                },
                {
                    typeIds: [8],
                    neededInBusinessTypeIds: [1, 10, 11, 12]
                }
            ],
            neededInBusinessTypeIds: [1, 10, 11, 12]
        },
        {
            categoryName: 'Passport or Driving licence or Residency permit',
            documents: [
                {
                    typeIds: [4],
                    neededInBusinessTypeIds: [1, 10, 11, 12]
                },
                {
                    typeIds: [3],
                    neededInBusinessTypeIds: [1, 10, 11, 12]
                },
                {
                    typeIds: [6],
                    neededInBusinessTypeIds: [1, 10, 11, 12]
                }
            ],
            neededInBusinessTypeIds: [1, 10, 11, 12]
        },
        {
            categoryName: 'Bank statement in the Business name or Void cheque',
            isBankUpdateCategory: true,
            documents: [
                {
                    typeIds: [1],
                    neededInBusinessTypeIds: [2]
                },
                {
                    typeIds: [8],
                    neededInBusinessTypeIds: [2]
                }
            ],
            neededInBusinessTypeIds: [2]
        },
        {
            categoryName: 'Utility bill or Rental agreement or Business rates bill',
            documents: [
                {
                    typeIds: [7],
                    neededInBusinessTypeIds: [2, 12]
                },
                {
                    typeIds: [5],
                    neededInBusinessTypeIds: [2, 12]
                },
                {
                    typeIds: [2],
                    neededInBusinessTypeIds: [2, 12]
                }
            ],
            neededInBusinessTypeIds: [2, 12]
        },
        {
            categoryName: 'Passport or driving Licence',
            documents: [
                {
                    typeIds: [4],
                    neededInBusinessTypeIds: [2]
                },
                {
                    typeIds: [3],
                    neededInBusinessTypeIds: [2]
                }
            ],
            neededInBusinessTypeIds: [2]
        }
    ]
};

export const getDocumentsCategories = (country, source, resellerName, isAdmin) => {
    let categories = JSON.parse(
        JSON.stringify(
            DocumentsCategoriesCountryWise[country] || DocumentsCategoriesCountryWise[MerchantCountries.UNITED_KINGDOM]
        )
    );

    if (source === 'BANK_UPDATE') {
        categories.push({
            categoryName: 'Please add at least one document',
            isAGenericCategory: true,
            isBankUpdateCategory: true,
            documents: [
                {
                    typeIds: [1],
                    neededInBusinessTypeIds: [3, 4, 5, 6, 7, 8, 9]
                },
                {
                    typeIds: [8],
                    neededInBusinessTypeIds: [3, 4, 5, 6, 7, 8, 9]
                }
            ],
            neededInBusinessTypeIds: [3, 4, 5, 6, 7, 8, 9]
        });
    } else {
        categories.push({
            categoryName: 'Please add at least one document',
            isAGenericCategory: true,
            documents: [
                {
                    typeIds: [1],
                    neededInBusinessTypeIds: [3, 4, 5, 6, 7, 8, 9]
                },
                {
                    typeIds: [2],
                    neededInBusinessTypeIds: [3, 4, 5, 6, 7, 8, 9]
                },
                {
                    typeIds: [3],
                    neededInBusinessTypeIds: [3, 4, 5, 6, 7, 8, 9]
                },
                {
                    typeIds: [4],
                    neededInBusinessTypeIds: [3, 4, 5, 6, 7, 8, 9]
                },
                {
                    typeIds: [5],
                    neededInBusinessTypeIds: [3, 4, 5, 6, 7, 8, 9]
                },
                {
                    typeIds: [6],
                    neededInBusinessTypeIds: [3, 4, 5, 6, 7, 8, 9]
                },
                {
                    typeIds: [7],
                    neededInBusinessTypeIds: [3, 4, 5, 6, 7, 8, 9]
                },
                {
                    typeIds: [8],
                    neededInBusinessTypeIds: [3, 4, 5, 6, 7, 8, 9]
                }
            ],
            neededInBusinessTypeIds: [3, 4, 5, 6, 7, 8, 9]
        });

        if (
            [MerchantCountries.UNITED_STATES, MerchantCountries.AUSTRALIA, MerchantCountries.UNITED_KINGDOM].includes(
                country
            )
        ) {
            categories.push({
                categoryName: 'Proof of organization tax info (optional)',
                isAGenericCategory: true,
                isBankUpdateCategory: true,
                isOptionalCategory: true,
                documents: [
                    {
                        typeIds: [14],
                        neededInBusinessTypeIds: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                    }
                ],
                neededInBusinessTypeIds: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            });
        }
        if ([MerchantCountries.UNITED_KINGDOM].includes(country)) {
            categories.push({
                categoryName: 'VAT document (optional)',
                isAGenericCategory: true,
                isOptionalCategory: true,
                documents: [
                    {
                        typeIds: [15],
                        neededInBusinessTypeIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
                    }
                ],
                neededInBusinessTypeIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
            });
        }
    }
    if (
        source === 'ACCOUNT_VERIFY' &&
        country === MerchantCountries.UNITED_KINGDOM &&
        resellerName === ResellerType.DATMAN &&
        isAdmin
    ) {
        categories.push({
            categoryName: 'Supporting Documents',
            isAdminCategory: true,
            isOptionalCategory: true,
            documents: [
                {
                    typeIds: [7, 12, 13],
                    neededInBusinessTypeIds: [1, 5]
                },
                {
                    typeIds: [12, 13],
                    neededInBusinessTypeIds: [2]
                }
            ],
            neededInBusinessTypeIds: [1, 2, 5]
        });
    }
    if (source === 'AGENT_UPLOAD') {
        categories.push({
            isOptionalCategory: true,
            categoryName: 'Street view',
            isBankUpdateCategory: true,
            documents: [
                {
                    typeIds: [12],
                    neededInBusinessTypeIds: [1, 2]
                }
            ],
            neededInBusinessTypeIds: [1, 2]
        });
    }

    return categories;
};
