import React, { useState, useEffect } from 'react';
import { useGlobalStateContext, useAdminFunctionsContext } from 'contexts';
import ManagePartnersComponent from './manage-partners.component';
import { AdyenService, DnaService, AdminService } from 'services';
import { DNAEventStatus } from 'utils/enums/pdqOrderStatus';
import { UserType } from 'utils/enums/UserType';
import moment from 'moment';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
export default function ManagePartners() {
    const { globalState } = useGlobalStateContext();
    const [acquirers, setAcquirers] = useState({});
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const { adminFunctions, setAdminFunctions } = useAdminFunctionsContext();
    const [adyenAccountId, setAdyenAccountId] = useState('');
    const [dnaApplicationId, setDnaApplicationId] = useState('');
    const [adyenAccount, setAdyenAccount] = useState({});
    const [dnaAccount, setDnaAccount] = useState({});
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [dnaErrorList, setDnaErrorList] = useState([]);
    const [messageType, setMessageType] = useState('');
    const [merchantData, setMerchantData] = useState({});
    const [pdqFee, setPdqFee] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [adyenCloseModal, setAdyenCloseModal] = useState(false);
    const [disable, setDisable] = useState(false);
    const isAdyenDisabled = adyenAccountId && acquirers.adyen;
    const isDnaDisabled =
        dnaApplicationId && acquirers.dna && dnaAccount.applicationStatus !== DNAEventStatus.NEED_ADDITIONAL_INFO;
    const needAdditionalInfoStatus =
        dnaApplicationId && dnaAccount.applicationStatus !== DNAEventStatus.NEED_ADDITIONAL_INFO;
    const isSuperAdmin = globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isAdyenOnboardingDisabled =
        globalState.merchant?.status === MerchantStatus.ACTIVE &&
        adminFunctions.accountVerify &&
        adminFunctions.bankVerify;

    useEffect(() => {
        const isDnaOnboarded = adminFunctions.dnaApplicationId ? true : false;
        const isAdyenOnboarded = adminFunctions.adyenAccountId ? true : false;

        if (isDnaOnboarded || isAdyenOnboarded) {
            setAcquirers({ ...acquirers, adyen: isAdyenOnboarded, dna: isDnaOnboarded });
            setAdyenAccountId(adminFunctions?.adyenAccountId);
            setDnaApplicationId(adminFunctions?.dnaApplicationId);
        } else {
            setAcquirers({ adyen: false, dna: false });
            setAdyenAccountId('');
            setDnaApplicationId('');
        }

        // eslint-disable-next-line
    }, [adminFunctions.adyenAccountId, adminFunctions.dnaApplicationId]);

    useEffect(() => {
        if (!checkEmptyValue(acquirers)) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [acquirers]);

    const getDnaAccounts = async () => {
        const response = await DnaService.getDnaAccount({ merchantId: adminFunctions.merchantId });
        if (response.isSuccesfully && response.data.dnaAccounts.length > 0) {
            const result = response.data.dnaAccounts[0];
            setDnaAccount(result);
        } else {
            setDnaAccount({});
        }
    };
    const getDnaMerchantData = async () => {
        const response = await AdminService.getPdqMerchantData(adminFunctions.merchantId);
        if (response.isSuccesfully) {
            let tempMerchantData = response.data.merchantData;
            tempMerchantData = {
                ...tempMerchantData,
                birthDate: moment(tempMerchantData.birthDate, 'DD-MM-YYYY').format('D MMM YYYY')
            };
            setMerchantData(tempMerchantData);
            setPdqFee(response.data.fees);
        } else {
            setMerchantData({});
            setPdqFee({});
        }
    };
    const getAdyenAccounts = async () => {
        const response = await AdyenService.getAdyenAccounts(adminFunctions?.merchantId);
        if (response.isSuccesfully) {
            setAdyenAccount(response.data);
        } else {
            setAdyenAccount({});
        }
    };
    const closeAdyenAccount = async () => {
        setModalLoading(true);
        const response = await AdyenService.closeAdyenAccount(adminFunctions.merchantId);
        if (response.isSuccesfully) {
            setShowMessage(true);
            setMessageType('success');
            setMessage('Your adyen account has been closed successfully');
        } else {
            setShowMessage(true);
            setMessageType('error');
            setMessage(response?.data[0]?.errorDescription);
        }
        setAdyenCloseModal(false);
        setModalLoading(false);
        if (adminFunctions.merchantId) {
            getAdyenAccounts();
        }
    };
    const onConfirm = async () => {
        let tempdata = merchantData;
        tempdata = { ...tempdata, birthDate: moment(tempdata.birthDate).format('YYYY-MM-DD') };

        setModalLoading(true);
        const response = await DnaService.submitDnaOnboarding(adminFunctions.merchantId, {
            merchantData: tempdata,
            pdqFee
        });
        if (response.isSuccesfully) {
            const dnaApplicationId = response.data.dnaApplicationId;
            setDnaApplicationId(dnaApplicationId);
            setAdminFunctions({
                ...adminFunctions,
                dnaApplicationId: dnaApplicationId
            });
            setAcquirers({
                ...acquirers,
                dna: true
            });
            getDnaAccounts();
            setOpenModal(false);
            setShowMessage(true);
            setMessage(response.data.message);
            setMessageType('success');
        } else {
            if (response.data?.errors) {
                setMessage('');
                setShowMessage(false);
                let dnaErrors = response.data?.errors.split('.')[0];

                setMessage(dnaErrors);
            } else {
                setMessage(response.data?.message ? response.data?.message : 'An error occurred!');
            }
            setMessageType('error');
            setShowMessage(true);
        }
        setModalLoading(false);
    };
    useEffect(() => {
        if (adminFunctions && adminFunctions?.merchantId) {
            getDnaAccounts();
            getDnaMerchantData();
            getAdyenAccounts();
        }
        // eslint-disable-next-line
    }, [adminFunctions.merchantId]);
    const openDnaForm = async () => {
        if (Object.keys(merchantData).length === 0) {
            setShowMessage(true);
            setMessage('No DNA onboarding request for this merchant');
            setMessageType('error');
        } else setOpenModal(true);
    };
    const submitPartnerApplication = async () => {
        setLoading(true);
        let response;
        // Persist the current state in localStorage/sessionStorage
        localStorage.setItem('isSubmittingAdyen', true);

        console.log('acquirers', acquirers);
        if (acquirers.obn) {
            //response = await PartnerObnService.submitPartnerApplication(globalState.merchant?.id);
        } else if (
            acquirers.adyen ||
            (adminFunctions?.missingInfoForAdyen && adminFunctions?.missingInfoForAdyen.length > 0)
        ) {
            console.log('submitting adyen');
            setDisable(true);
            response = await AdyenService.submitAdyenOnboarding(globalState.merchant?.id);
        }

        if (!response) {
            setError('Invalid selection');
            localStorage.removeItem('isSubmittingAdyen'); // Clean up after error
        }
        // if (response && !response.isSuccesfully && response.error.error) {
        //     if (response.error.error.data) {
        //         const errorDetail = response.error.error.data;
        //         let errorMessages = errorDetail.message || response.error.error.message;
        //         if (errorDetail.errors) {
        //             errorMessages = `<br />-> ${errorDetail.errors.join('<br />-> ')}`;
        //         } else if (errorDetail.invalidFields) {
        //             errorMessages = `<br />-> ${errorDetail.invalidFields
        //                 .map((f) => `${f.fieldType.fieldName} - ${f.errorDescription}`)
        //                 .join('<br />-> ')}`;
        //         }
        //         // setAdminFunctions({
        //         //     ...adminFunctions,
        //         // });
        //     } else {
        //         setError(response.error.error.message);
        //     }
        // }

        if (response && response.isSuccesfully) {
            setShowMessage(true);
            setMessage(response.data.message);
            setMessageType('success');
            localStorage.removeItem('isSubmittingAdyen'); // Clear submission state after success
        } else {
            setShowMessage(true);
            let message;
            if (response?.data?.message) {
                message = response.data.message;
            } else if (response.data[0]?.errorDescription) {
                message = response.data[0]?.errorDescription;
            } else if (response.data?.errorMessage) {
                message = response.data?.errorMessage[0];
            } else if (response.data?.errors) {
                setMessage('');
                setShowMessage(false);
                let dnaErrors = response.data?.errors;
                setDnaErrorList(dnaErrors);
            } else {
                message = 'An error occurred!';
            }

            setMessage(message);
            setMessageType('error');
            localStorage.removeItem('isSubmittingAdyen'); // Clear submission state after error
        }

        setLoading(false);
    };

    // On page load or return, check if the submission is still in progress
    useEffect(() => {
        const isSubmitting = localStorage.getItem('isSubmittingAdyen');
        if (isSubmitting) {
            setDisable(true);
        }
    }, []);

    const toggleAcquirers = (name, checked) => {
        if (name === 'dna' && !isAdyenDisabled) {
            setAcquirers({ ...acquirers, adyen: false, dna: checked });
        } else if (!isDnaDisabled) {
            setAcquirers({ ...acquirers, dna: false, [name]: checked });
        } else {
            setAcquirers({ ...acquirers, [name]: checked });
        }
    };

    const getAttribute = (affiliate, name) => {
        return <span>&mdash;</span>;
    };

    return (
        <ManagePartnersComponent
            error={error}
            toggleAcquirers={toggleAcquirers}
            acquirers={acquirers}
            submitPartnerApplication={submitPartnerApplication}
            getAttribute={getAttribute}
            isLoading={isLoading}
            adyenAccountId={adyenAccountId}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            setMessage={setMessage}
            messageType={messageType}
            message={message}
            row={adyenAccount}
            dnaAccount={dnaAccount}
            dnaErrorList={dnaErrorList}
            dnaApplicationId={dnaApplicationId}
            needAdditionalInfoStatus={needAdditionalInfoStatus}
            merchantData={merchantData}
            setMerchantData={setMerchantData}
            pdqFee={pdqFee}
            setPdqFee={setPdqFee}
            openModal={openModal}
            setOpenModal={setOpenModal}
            adminFunction={adminFunctions}
            onConfirm={onConfirm}
            modalLoading={modalLoading}
            adminFunctions={adminFunctions}
            isDnaDisabled={isDnaDisabled}
            openDnaForm={openDnaForm}
            closeAdyenAccount={closeAdyenAccount}
            setAdyenCloseModal={setAdyenCloseModal}
            adyenCloseModal={adyenCloseModal}
            isSuperAdmin={isSuperAdmin}
            isAdyenOnboardingDisabled={isAdyenOnboardingDisabled}
            disable={disable}
        />
    );
}
