import React, { useState, useEffect } from 'react';
import DatePickerComponent from './date-picker.component';

import moment from 'moment';

function DatePicker(props) {
    const { value, minDate } = props;
    const [error, setError] = useState(false);
    useEffect(() => {
        if (value && minDate && moment(value).isBefore(moment(minDate))) {
            setError(true);
        } else {
            setError(false);
        }
    }, [value, minDate]);

    return <DatePickerComponent {...props} error={error} />;
}

export default DatePicker;
